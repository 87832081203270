import { React, useState, useEffect } from "react";
import { useMyData } from './MyProvider';

export default function ApplicationAddReqs(props) {

  const { dataApplication, setDataApplication } = useMyData();

  let show = false;
  let tsdate = new Date(props.dataApplication.tsdate);

  if ( props.month.getMonth() == tsdate.getMonth() ) {
    show = true;
  }

  const [req1, setReq1] = useState("");
  const [req2, setReq2] = useState("");
  const [req3, setReq3] = useState("");

  const wday = ['日','月','火','水','木','金','土'];

  const getDateStr = (req) => {
    let dateStr = "";
    if(req.length > 0) {
      const reqDateStr = req[0].substr(0,req[0].length -1);
      const reqDate = new Date(reqDateStr);
      let term = new Array();
      req.sort().forEach((t)=>{
        if(t.substr(-1)=="a") term.push('午前');
        if(t.substr(-1)=="b") term.push('午後');
        if(t.substr(-1)=="c") term.push('夜間');
      });

      dateStr = reqDate.getFullYear() + "年"+(reqDate.getMonth()+1) + "月"+ reqDate.getDate()+"日（"+ wday[reqDate.getDay()] +"）" + term.join("・");
    }
    return dateStr;
  }

  useEffect(()=>{
    setReq1 ( getDateStr ( dataApplication.req1 ) );
    setReq2 ( getDateStr ( dataApplication.req2 ) );
    setReq3 ( getDateStr ( dataApplication.req3 ) );

  },[dataApplication]);


  const delReq1 = () => {
    let mode = "step1";

    setDataApplication({
      ...dataApplication,
      step1:{},
      req1:[],
      mode
    });
  };

  const delReq2 = () => {
    let mode = "step1";
    if(dataApplication.req1.length>0) {
      mode = "step2";
    }

    setDataApplication({
      ...dataApplication,
      step2:{},
      req2:[],
      mode
    });
  };

  const delReq3 = () => {
    let mode = "step1";

    if(dataApplication.req1.length>0) {
      mode = "step2";
      if(dataApplication.req2.length>0) {
        mode = "step3";
      }
    }

    setDataApplication({
      ...dataApplication,
      step3:{},
      req3:[],
      mode
    });
  };

  return (
    <>
    {show &&
      <>
      { dataApplication.mode=="step1" && dataApplication.req1.length==0 &&
        <p>第一希望を選択してください。</p>
      }
      { dataApplication.mode=="step2" && dataApplication.req2.length==0 &&
        <p>第二希望を選択してください。第二希望がない場合は「次へ」を押してください。</p>
      }
      { dataApplication.mode=="step3" && dataApplication.req3.length==0 &&
        <p>第三希望を選択してください。第三希望がない場合は「次へ」を押してください。</p>
      }
      <ul className="Choicebox">
        { (dataApplication.req1.length>0)
          ? <li><a onClick={delReq1}>第一希望 {dataApplication.req1.length==1 && <span className="err">(2区分以上の選択が必須)</span>}<span className="Choicebox__date">{req1}</span><span className="Choicebox__btn">&#x00D7;</span></a></li>
          : <li className="tbd">&nbsp;</li>
        }
        { (dataApplication.req2.length>0)
          ? <li><a onClick={delReq2}>第二希望 {dataApplication.req2.length==1 && <span className="err">(2区分以上の選択が必須)</span>}<span className="Choicebox__date">{req2}</span><span className="Choicebox__btn">&#x00D7;</span></a></li>
          : <li className="tbd">&nbsp;</li>
        }
        { (dataApplication.req3.length>0)
          ? <li><a onClick={delReq3}>第三希望 {dataApplication.req3.length==1 && <span className="err">(2区分以上の選択が必須)</span>}<span className="Choicebox__date">{req3}</span><span className="Choicebox__btn">&#x00D7;</span></a></li>
          : <li className="tbd">&nbsp;</li>
        }
      </ul>
      </>
    }
    </>
 );

}
