import { React, useState, useEffect, Fragment } from "react";
import { useParams } from "react-router-dom";
import { useMyData } from './MyProvider';
import { useInput, useCheckbox } from "./hooks";

export default function ApplicationAddConfirm() {
  let {id} = useParams();

  const { dataApplication, setDataApplication,myInfo,dataMy,getAddressFromZip } = useMyData();

  const wday = ['日','月','火','水','木','金','土'];

  const getDateStr = (req) => {
    let dateStr = "";
    if(req.length > 0) {
      const reqDateStr = req[0].substr(0,req[0].length -1);
      const reqDate = new Date(reqDateStr);
      let term = new Array();
      req.sort().forEach((t)=>{
        if(t.substr(-1)=="a") term.push('午前');
        if(t.substr(-1)=="b") term.push('午後');
        if(t.substr(-1)=="c") term.push('夜間');
      });

      dateStr = reqDate.getFullYear() + "年"+(reqDate.getMonth()+1) + "月"+ reqDate.getDate()+"日（"+ wday[reqDate.getDay()] +"）" + term.join("・");
    }
    return dateStr;
  }

  const makeRsvConfirmStr = (step) => {
    let ele = [];
    let retStr="";
    if(step.encho==1 && (step.enchoBefore > 0 || step.enchoAfter > 0) )  {
      retStr+=" 延長";
      if(step.enchoBefore > 0) {
        if(step.enchoBefore==60) {
          retStr+=" 前1時間";
        } else {
          retStr+=" 前" + step.enchoBefore + "分";
        }
      }
      if(step.enchoAfter > 0) {
        if(step.enchoAfter==60) {
          retStr+=" 後1時間";
        } else {
          retStr+=" 後" + step.enchoAfter + "分";
        }
      }
      ele.push(<>{retStr}</>);
      ele.push(<br />);
    }
    step.optionRoom.forEach((e,i) => {
      let room="";
      let kubun="";
      if(e.room==2) room="小ホール";
      if(e.room==3) room="レセプションルーム";
      if(e.room==4) room="リハーサル室";
      if(e.room==5) room="練習室1";
      if(e.room==6) room="練習室2";
      if(e.room==7) room="練習室3";
      if(e.room==8) room="練習室4";
      if(e.room==9) room="練習室5";
      if(e.room==10) room="練習室6";

      if(e.time==1) kubun="午前";
      if(e.time==2) kubun="午後";
      if(e.time==3) kubun="午前・午後";
      if(e.time==4) kubun="夜間";
      if(e.time==6) kubun="午後・夜間";
      if(e.time==7) kubun="終日";

      ele.push(<Fragment key={i}>併用施設 {room} {kubun}<br /></Fragment>);

    });

    return <>{ele}</>;
  }

  useEffect(() => {
    console.log(dataApplication);
  },[]);


  const nextStep = () => {
    window.scrollTo({
      top: 0,
    });

    setDataApplication({
      ...dataApplication,
      "mode": "step7",
    })
  };

  const prevStep = () => {
    window.scrollTo({
      top: 0,
    });

    setDataApplication({
      ...dataApplication,
      "mode": "step5",
    })
  };
  return (
    <>
      <h1 className="pagetopLabel">公演計画情報(確認)</h1>

      <ul className="Progressbar">
        <li className="complete"><span>第一希望選択</span></li>
        <li className="complete"><span>第二希望選択</span></li>
        <li className="complete"><span>第三希望選択</span></li>
        <li className="complete"><span>利用許可<br />申請情報の入力</span></li>
        <li className="complete"><span>公演計画<br />申請情報の入力</span></li>
        <li className="active"><span>確認</span></li>
        <li><span>抽選申込完了</span></li>
      </ul>

      <h6>【利用日】</h6>
      <table>
        <tbody>
          <tr>
            <th>第一希望</th>
            {dataApplication.req1.length > 0 ?
            <td>{dataApplication.venue==10 ? '大' : '小'}ホール　{getDateStr(dataApplication.req1)}
              {makeRsvConfirmStr(dataApplication.step1)}
            </td>
            :
            <td>なし</td>
            }
          </tr>
          <tr>
            <th>第二希望</th>
            {dataApplication.req2.length > 0 ?
            <td>{dataApplication.venue==10 ? '大' : '小'}ホール　{getDateStr(dataApplication.req2)}
              {makeRsvConfirmStr(dataApplication.step2)}
            </td>
          :
            <td>なし</td>
            }
          </tr>
          <tr>
            <th>第三希望</th>
            {dataApplication.req3.length > 0 ?
            <td>{dataApplication.venue==10 ? '大' : '小'}ホール　{getDateStr(dataApplication.req3)}
              {makeRsvConfirmStr(dataApplication.step3)}
            </td>
          :
            <td>なし</td>
            }
          </tr>
        </tbody>
      </table>

      <h6>【申請者情報】</h6>
      <table className="Form">
        <tbody><tr>
          <th>団体名</th>
          <td>{dataApplication.apply.r_name}</td>
        </tr>
        <tr>
          <th>代表者名</th>
          <td>
            {dataApplication.apply.r_d_l_name}
            {dataApplication.apply.r_d_f_name}
          </td>
        </tr>
        <tr>
          <th>代表者住所</th>
          <td>
            郵便番号 {dataApplication.apply.r_d_zipcode}<br />
            {dataApplication.apply.r_d_address}
          </td>
        </tr>
        <tr>
          <th>電話番号1</th>
          <td>{dataApplication.apply.r_d_tel1a}ー{dataApplication.apply.r_d_tel1b}ー{dataApplication.apply.r_d_tel1c}</td>
        </tr>
        <tr>
          <th>電話番号2</th>
          <td>
            {dataApplication.apply.r_d_tel2a &&
            <>
            {dataApplication.apply.r_d_tel2a}ー{dataApplication.apply.r_d_tel2b}ー{dataApplication.apply.r_d_tel2c}
            </>
            }
          </td>
        </tr>
        <tr>
          <th>FAX</th>
          <td>
            {dataApplication.apply.r_d_faxa &&
            <>
            {dataApplication.apply.r_d_faxa}ー{dataApplication.apply.r_d_faxb}ー{dataApplication.apply.r_d_faxc}
            </>
            }
          </td>
        </tr>
      </tbody>
      </table>

      <h6>【担当者情報】</h6>
      <div className="txt">
        <p>
          {dataApplication.apply.check1.includes("no") && <><label>代表者とは別の担当者はいません。</label><br /></>}
          {dataApplication.apply.check2.includes("no") && <><label>担当者は代表者と住所（書類送付先）は同じです。</label><br /></>}
        </p>
      </div>
      {!dataApplication.apply.check1.includes("no") &&
      <table className="Form" summary="#">
        <tbody>
        <tr>
          <th>担当者名</th>
          <td>
            {dataApplication.apply.r_t_l_name}
            {dataApplication.apply.r_t_f_name}
          </td>
        </tr>
        <tr>
          <th>担当者名フリガナ</th>
          <td>
            {dataApplication.apply.r_t_l_kana}
            {dataApplication.apply.r_t_f_kana}
          </td>
        </tr>
        {!dataApplication.apply.check2.includes("no") &&
        <tr>
          <th>担当者ご住所</th>
          <td>
            郵便番号 {dataApplication.apply.r_t_zipcode}<br />
            {dataApplication.apply.r_t_address}
          </td>
        </tr>
        }
        <tr>
          <th>電話番号1</th>
          <td>{dataApplication.apply.r_t_tel1a}ー{dataApplication.apply.r_t_tel1b}ー{dataApplication.apply.r_t_tel1c}</td>
        </tr>
        <tr>
          <th>電話番号2</th>
          <td>
          {dataApplication.apply.r_t_tel2a &&
            <>
            {dataApplication.apply.r_t_tel2a}ー{dataApplication.apply.r_t_tel2b}ー{dataApplication.apply.r_t_tel2c}
            </>
            }
          </td>
        </tr>
        <tr>
          <th>FAX</th>
          <td>
          {dataApplication.apply.r_t_faxa &&
            <>
            {dataApplication.apply.r_t_faxa}ー{dataApplication.apply.r_t_faxb}ー{dataApplication.apply.r_t_faxc}
            </>
            }
          </td>
        </tr>
        <tr>
          <th>メールアドレス</th>
          <td>{dataApplication.apply.r_t_email}</td>
        </tr>
      </tbody>
      </table>
      }

      <h6>【事務担当者情報】</h6>
      <div className="txt">
        {dataApplication.apply.check3.includes("yes") 
         ? <p><label>申請者とは別団体の事務担当者がいます。</label></p>
         : <p><label>申請者とは別団体の事務担当者はいません。</label></p>
        }
        
      </div>
      {dataApplication.apply.check3.includes("yes") && (dataApplication.apply.check1.includes("no") || dataApplication.apply.check2.includes("no")) &&
      <table className="Form" summary="#" id="Area">
        <tbody><tr>
          <th>団体名</th>
          <td>{dataApplication.apply.j_name}</td>
        </tr>
        <tr>
          <th>代表者名</th>
          <td>
            {dataApplication.apply.j_d_l_name}
            {dataApplication.apply.j_d_f_name}
          </td>
        </tr>
        <tr>
          <th>代表者名フリガナ</th>
          <td>
            {dataApplication.apply.j_d_l_kana}
            {dataApplication.apply.j_d_f_kana}
          </td>
        </tr>
        <tr>
          <th>担当者名</th>
          <td>
            {dataApplication.apply.j_t_l_name}
            {dataApplication.apply.j_t_f_name}
          </td>
        </tr>
        <tr>
          <th>担当者名フリガナ</th>
          <td>
            {dataApplication.apply.j_t_l_kana}
            {dataApplication.apply.j_t_f_kana}
          </td>
        </tr>
        <tr>
          <th>ご住所</th>
          <td>
            郵便番号 {dataApplication.apply.j_zipcode}<br />
            {dataApplication.apply.j_address}
          </td>
        </tr>
        <tr>
          <th>電話番号1</th>
          <td>{dataApplication.apply.j_tel1a}ー{dataApplication.apply.j_tel1b}ー{dataApplication.apply.j_tel1c}</td>
        </tr>
        <tr>
          <th>電話番号2</th>
          <td>
          {dataApplication.apply.j_tel2a &&
            <>
            {dataApplication.apply.j_tel2a}ー{dataApplication.apply.j_tel2b}ー{dataApplication.apply.j_tel2c}
            </>
            }
          </td>
        </tr>
        <tr>
          <th>FAX</th>
          <td>
          {dataApplication.apply.j_faxa &&
            <>
            {dataApplication.apply.j_faxa}ー{dataApplication.apply.j_faxb}ー{dataApplication.apply.j_faxc}
            </>
            }
          </td>
        </tr>
        <tr>
          <th>メールアドレス</th>
          <td>{dataApplication.apply.j_email}</td>
        </tr>
      </tbody>
      </table>
      }

      <h6>【公演計画情報】</h6>
      <table className="Form" summary="#">
        <tbody><tr>
          <th>主催者の概要</th>
          <td>
          {dataApplication.plan.dtype=="1" && "行政"}
          {dataApplication.plan.dtype=="2" && "楽団"}
          {dataApplication.plan.dtype=="3" && "音楽事務所"}
          {dataApplication.plan.dtype=="4" && "アマチュア団体"}
          {dataApplication.plan.dtype=="5" && "個人"}
          {dataApplication.plan.dtype=="6" && "NPO"}
          {dataApplication.plan.dtype=="7" && "法人"}
          {dataApplication.plan.dtype=="8" && "その他"}
          {dataApplication.plan.dtype=="9" && "教育機関"}

          &nbsp;

          {dataApplication.plan.dtype=="9" &&
          <>
            {dataApplication.plan.dtype1=="1" && "公立（横浜市立）"}
            {dataApplication.plan.dtype1=="2" && "公立（県立）"}
            {dataApplication.plan.dtype1=="3" && "公立（他）"}
            {dataApplication.plan.dtype1=="4" && "私立"}
          </>
          }

          &nbsp;

          {dataApplication.plan.dtype=="9" &&
          <>
            {dataApplication.plan.dtype2=="1" && "幼稚園"}
            {dataApplication.plan.dtype2=="2" && "小学校"}
            {dataApplication.plan.dtype2=="3" && "中学校"}
            {dataApplication.plan.dtype2=="4" && "高校"}
            {dataApplication.plan.dtype2=="5" && "大学"}
            {dataApplication.plan.dtype2=="6" && "その他学校"}
          </>
          }
          </td>
        </tr>
        <tr>
          <th rowSpan="3">当ホール使用履歴</th>
          <td>
          {dataApplication.plan.history=="1" && "初申請"}<br /><span>活動履歴・実績書類 &nbsp;</span>
          {dataApplication.plan.history=="1" &&
          <>
            {dataApplication.plan.file 
              ? <span> あり：<a href={process.env.REACT_APP_API_ENDPOINT + 'my/application/' + id + '/file/' + dataApplication.plan.file}>{dataApplication.plan.file_name}</a></span>
              : <span> なし</span>
            }
          </>
          }
          {dataApplication.plan.history=="2" && "申請は2回目以上だが、主催としての利用実績無し"}
          <span>{dataApplication.plan.history=="3" && "主催として同団体で利用実績あり"}</span><br />

          {dataApplication.plan.history=="3" &&
          <>
            {dataApplication.plan.history1=="1" && <>1回</>}
            {dataApplication.plan.history1=="2" && <>2回</>}
            {dataApplication.plan.history1=="3" && <>3回以上</>}
          </>
          }
        </td>
        </tr>
        <tr>
          <th style={{display: "none"}}>当ホール使用履歴</th>
          <td>
            <span>{dataApplication.plan.past.includes("1") && "他主催公演に同団体として出演実績あり"}</span><br />
            {dataApplication.plan.pastText}
          </td>
        </tr>
        <tr>
          <th style={{display: "none"}}>当ホール使用履歴</th>
          <td>
            <span>他ホールでの公演実績</span><br />
            { dataApplication.plan.pastOther.map((item) => (
              <>
              <div key={item.id}>{item.text}</div>
              </>
            ))}
          </td>
        </tr>
        <tr>
          <th>公演名</th>
          <td>{dataApplication.plan.title}</td>
        </tr>
        <tr>
          <th>入場料金等の最高額</th>
          <td>
            {dataApplication.venue==10 &&
            <>
            {dataApplication.plan.price==1 && "無料～2,000円"}
            {dataApplication.plan.price==2 && "2,001円～5,000円"}
            {dataApplication.plan.price==3 && "5,001円以上"}
            <br />
            </>
            }

            {dataApplication.venue==20 &&
            <>
            {dataApplication.plan.price==1 && "無料～3,000円"}
            {dataApplication.plan.price==2 && "3,001円以上"}
            <br />
            </>
            }
          </td>
        </tr>
        <tr>
          <th>対象入場者</th>
          <td>
            {dataApplication.plan.target=="1" && "一般"}
            {dataApplication.plan.target=="2" && "参加者のみ"}
            {dataApplication.plan.target=="3" && "関係者のみ"}
          </td>
        </tr>
        <tr>
          <th>集客見込み</th>
          <td>{dataApplication.plan.guests}名程度</td>
        </tr>
        <tr>
          <th>入場方式</th>
          <td>
          {dataApplication.plan.ticket=="1" && "指定席"}
          {dataApplication.plan.ticket=="2" && "当日指定引換"}
          {dataApplication.plan.ticket=="3" && "自由席（チケット発行あり）"}
          {dataApplication.plan.ticket=="4" && "自由席（チケット発行なし）"}
          </td>
        </tr>
        <tr>
          <th>出演者数</th>
          <td>{dataApplication.plan.players}名</td>
        </tr>
        <tr>
          <th>出演者/演奏団体</th>
          <td>
            { dataApplication.plan.player.map((item) => (
            <div key={item.id}>{item.text}</div>
            ))}
          </td>
        </tr>
        <tr>
          <th>予定曲目</th>
          <td>
            { dataApplication.plan.programs.map((item) => (
            <div key={item.id}>{item.text}</div>
            ))}
          </td>
        </tr>
        <tr>
          <th>公演ジャンル</th>
          <td>
          {dataApplication.plan.genre=="1" && "クラシック音楽"}
          {dataApplication.plan.genre=="2" && "ポピュラー音楽"}
          {dataApplication.plan.genre=="3" && "その他の音楽"}
          </td>
        </tr>
        <tr>
          <th rowSpan="6">公演の概要</th>
          <td>
            <span className="ttl">使用楽器等</span><br />
            {dataApplication.plan.part.includes("1") && "声楽"}&nbsp;
            {dataApplication.plan.part.includes("2") && "弦楽器"}&nbsp;
            {dataApplication.plan.part.includes("3") && "管楽器"}&nbsp;
            {dataApplication.plan.part.includes("4") && "鍵盤楽器"}&nbsp;
            {dataApplication.plan.part.includes("5") && "打楽器"}&nbsp;
            {dataApplication.plan.part.includes("6") && "合唱"}

            {dataApplication.plan.part.includes("6") &&
            <>
              &nbsp;
              {dataApplication.plan.part6=="1" && "（女声）"}
              {dataApplication.plan.part6=="2" && "（男声）"}
              {dataApplication.plan.part6=="3" && "（混声）"}
              {dataApplication.plan.part6=="4" && "（児童）"}
            </>
            }
          </td>
        </tr>
        <tr>
          <th style={{display: "none"}}>公演の概要</th>
          <td>
            <span className="ttl">実施体制</span><br />
            {dataApplication.plan.type=="1" && "1団体"}
            {dataApplication.plan.type=="2" &&
              <>
              "その他"
              {dataApplication.plan.type2}
              </>
            }
            {dataApplication.plan.type=="3" && "複数団体（教室）合同"}
            <br />
            <div style={{whiteSpace:"pre-wrap"}}>
            {dataApplication.plan.type3}
            </div>
            </td>
        </tr>
        <tr>
          <th style={{display: "none"}}>公演の概要</th>
          <td>
            <span className="ttl">編成</span><br />
            {dataApplication.plan.form=="1" && "ソロ"}
            {dataApplication.plan.form=="2" && "アンサンブル"}
            {dataApplication.plan.form=="3" && "オーケストラ・吹奏楽・合唱（80名未満）"}
            {dataApplication.plan.form=="4" && "オーケストラ・吹奏楽・合唱（80名以上）"}
          </td>
        </tr>
        <tr>
          <th style={{display: "none"}}>公演の概要</th>
          <td>
            <span className="ttl">内容</span><br />
            {dataApplication.plan.program=="1" && "演奏会"}
            {dataApplication.plan.program=="2" && "フェスティバル・大会"}
            {dataApplication.plan.program=="3" && "その他"}
              {dataApplication.plan.programText}
            {dataApplication.plan.program=="4" && "発表会"}
              &nbsp;
              {dataApplication.plan.recital.includes("1") && "幼児 "}
              {dataApplication.plan.recital.includes("2") && "小中学生 "}
              {dataApplication.plan.recital.includes("3") && "高校生 "}
              {dataApplication.plan.recital.includes("4") && "大学〜大学院生 "}
              {dataApplication.plan.recital.includes("5") && "成年 "}
          </td>
        </tr>
        <tr>
          <th style={{display: "none"}}>公演の概要</th>
          <td>
            {dataApplication.plan.pa=="1" && "PAなし"}
            {dataApplication.plan.pa=="2" && "PAあり（主催者業者手配あり）"}
            {dataApplication.plan.pa=="3" && "PAあり（主催者業者手配なし）"}
          </td>
        </tr>
        <tr>
          <th style={{display: "none"}}>公演の概要</th>
          <td>
            {dataApplication.plan.lighting=="1" && "照明演出なし"}
            {dataApplication.plan.lighting=="2" && "照明演出あり（主催者業者手配あり）"}
            {dataApplication.plan.lighting=="3" && "照明演出あり（主催者業者手配なし）"}
          </td>
        </tr>
        <tr>
          <th rowSpan={dataApplication.venue==10 ? 2 : 1}>ホール所有楽器の使用希望</th>
          <td>
            <span>※ご希望の使用機種を入力してください。ホール利用状況によりご希望に沿えない場合があります。パイプオルガンの使用には別途使用申込書が必要です。<br /></span>
            <br />
            {dataApplication.plan.piano.includes("1") 
            ?
              <>

                フルコンサートグランドピアノ
                &nbsp;
                {dataApplication.plan.piano_num=="1" && "1台"}
                {dataApplication.plan.piano_num=="2" && "2台"}
                <br />

              1台目 &nbsp;
              {dataApplication.plan.piano1=="0" && "機種指定なし"}
              {dataApplication.plan.piano1=="1" && "スタインウェイD274"}
              {dataApplication.plan.piano1=="2" && "ベーゼンドルファーインペリアル"}
              {dataApplication.plan.piano1=="3" && "ヤマハCFX"}
              <br />

              {dataApplication.plan.piano_num=="2" &&
              <>
              2台目 &nbsp;
              {dataApplication.plan.piano2=="0" && "機種指定なし"}
              {dataApplication.plan.piano2=="1" && "スタインウェイD274"}
              {dataApplication.plan.piano2=="2" && "ベーゼンドルファーインペリアル"}
              {dataApplication.plan.piano2=="3" && "ヤマハCFX"}
              <br />
              </>
              }

              </>
            :
              <>フルコンサートグランドピアノ 利用なし</>
            }
          </td>
        </tr>
        {dataApplication.venue==10 &&
        <tr>
          <th style={{display: "none"}}>ホール所有楽器の使用希望</th>
          <td>
          {dataApplication.plan.organ.includes("1")
          ?
            <>
            パイプオルガン
            &nbsp;
            {dataApplication.plan.organist=="1" && "オルガニスト確定"}
            {dataApplication.plan.organist=="2" && "オルガニスト未定"}
            {dataApplication.plan.organist=="3" && "オルガニストの斡旋希望"}

            {dataApplication.plan.organist=="1" &&
            <>
              &nbsp;
              {dataApplication.plan.organistname}
            </>
            }
            </>
          :
            <>パイプオルガン 利用なし</>
          }
          </td>
        </tr>
        }
        <tr>
          <th>抽選会への参加</th>
          <td>
            {dataApplication.plan.lottery=="0" && "希望しません（ホール代行抽選）"}
            {dataApplication.plan.lottery=="1" && "希望します（来館抽選）"}
          </td>
        </tr>
        <tr>
          <th>備考メモ欄</th>
          <td>
            <div style={{whiteSpace:"pre-wrap"}}>
            {dataApplication.plan.comment != "" 
              ? <>{dataApplication.plan.comment}</>
              : <>なし</>
             }
            </div>
          </td>
        </tr>

      </tbody>
      </table>


      <div className="Form__btnbox">
        <div className="Form__btn-return">
          <input type="button" value="戻る" onClick={prevStep} />
        </div>
        <div className="Form__btn-confirm">
          <input value="申請する" type="submit" id="next" onClick={nextStep} />
        </div>
      </div>
    </>
  );

}
