import { React, useEffect, useState } from 'react';
import { Document, Page, Text, View, StyleSheet, Font } from '@react-pdf/renderer';
import { DataSet, Grid, Col } from "./atoms/Grid";

export default function ApplyDetailPdf(props) {

  Font.register({
    family: "NotoSansJP",
    src: "/css/fonts/NotoSansJP-Regular.otf",
  });


  // Create styles
  const styles = StyleSheet.create({
    page: {
      flexDirection: 'row',
      backgroundColor: '#E4E4E4'
    },
    section: {
      margin: "8mm 5mm",
      padding: 10,
      flexGrow: 1,
      fontFamily: 'NotoSansJP',
      fontSize: '3.0mm',
    },
    select: {
      border:"1px solid #000",
      borderRadius:"3mm",
    },
    h6: {
      fontFamily: 'NotoSansJP',
      fontSize: '3.0mm',
      marginTop: '3.0mm',
      marginBottom: '1.0mm',
    }
  });
  

  const wday = ['日','月','火','水','木','金','土'];

  const getDateStr = (req) => {
    let dateStr = "";
    if(req.length > 0) {
      const reqDateStr = req[0].substr(0,req[0].length -1);
      const reqDate = new Date(reqDateStr);
      let term = new Array();
      req.sort().forEach((t)=>{
        if(t.substr(-1)=="a") term.push('午前');
        if(t.substr(-1)=="b") term.push('午後');
        if(t.substr(-1)=="c") term.push('夜間');
      });
      let m = reqDate.getMonth()+1;
      if(m < 10) {
        m = '0' + m;
      }
      let d = reqDate.getDate();
      if(d < 10) {
        d = '0' + d;
      }

      dateStr = reqDate.getFullYear() + "年 " + m + "月 " + d + "日（"+ wday[reqDate.getDay()] +"）　" + term.join("・");
    }
    return dateStr;
  }
  const getRoomStr = (req) => {
    let retStr = "";
    if(req.optionRoom.length > 0) {
      let room = [];
      req.optionRoom.forEach(e => {
        if(e.room == 1) room.push('大ホール');
        if(e.room == 2) room.push('小ホール');
        if(e.room == 3) room.push('レセプションルーム');
        if(e.room == 4) room.push('リハーサル室');
        if(e.room == 5) room.push('練習室1');
        if(e.room == 6) room.push('練習室2');
        if(e.room == 7) room.push('練習室3');
        if(e.room == 8) room.push('練習室4');
        if(e.room == 9) room.push('練習室5');
        if(e.room == 10) room.push('練習室6');
      });
  
      if(room.length > 0) {
        retStr = '併設利用 ： ' + room.join("・");
      }
    }
    return retStr;
  }


  const getDataStr = (req , dataApplication) => {
    let dataStr = "";
    if(req.date) {
      dataStr += `${dataApplication.venue==10 ? '大' : '小'}ホール　`;
      dataStr += `${dataApplication.venue==10 && dataApplication.req1.seat==1 ? '（A型：全階利用）　' : ''}`;
      dataStr += `${dataApplication.venue==10 && dataApplication.req1.seat==2 ? '（B型：1階のみ）　' : ''}`;
  
      let term = [];
      if(req.time1 > 0) term.push('午前');
      if(req.time2 > 0) term.push('午後');
      if(req.time3 > 0) term.push('夜間');
  
      dataStr += req.date_t + "　" + term.join("・");
    }
    return dataStr;
  }
  const getOptionStr = (req) => {
    let retStr = "";
    if(req.date) {
      let room = [];
      if(req.option1 > 0) room.push('大ホール');
      if(req.option2 > 0) room.push('小ホール');
      if(req.option3 > 0) room.push('レセプションルーム');
      if(req.option4 > 0) room.push('リハーサル室');
      if(req.proom1 > 0) room.push('練習室1');
      if(req.proom2 > 0) room.push('練習室2');
      if(req.proom3 > 0) room.push('練習室3');
      if(req.proom4 > 0) room.push('練習室4');
      if(req.proom5 > 0) room.push('練習室5');
      if(req.proom6 > 0) room.push('練習室6');
  
      if(room.length > 0) {
        retStr = '併設利用 ： ' + room.join("・");
      }
    }
    return retStr;
  }
  
  const getOptionGuests = (req) => {
    let retStr = "";
    if(req.guests) {
      retStr = '集客見込 ： ' + req.guests + "名程度";
    }
    return retStr;
  }
  
  const getText = (item, key) => {
    let ret = [];
    item.forEach(element => {
      ret.push(element[key])
    });
    return ret;
  }
  
  if (props.data && props.data.req1 ) {
    const dataApplication = props.data;

    let no = 0;

    return (
      <Document>
      <Page size="A4">
        <View style={styles.section}>
          <Text style={{ fontSize: '5mm', letterSpacing: '1.5mm', textAlign: 'center' }}>
            {dataApplication.venue==10 ? '大' : '小'}ホール 抽選申し込み情報
             (
              {dataApplication.appnum ? dataApplication.appnum : '' }
              {dataApplication.no ? dataApplication.no : ''}
             )
          </Text>
  
          <Text style={styles.h6}>【利用日】</Text>
          {dataApplication.appnum 
          ?
          <>
          <DataSet
              keys = {['第一希望']}
              values = {
                dataApplication.req1.length > 0 ?
                [
                  (dataApplication.venue==10 ? '大' : '小') + 'ホール　'
                  + (dataApplication.venue==10 && dataApplication.step1.mainhallAB=="a" ? '（A型：全階利用）　' : '')
                  + (dataApplication.venue==10 && dataApplication.step1.mainhallAB=="b" ? '（B型：1階のみ）　' : '')
                  + getDateStr(dataApplication.req1),
                  getRoomStr(dataApplication.step1),
                  '集客見込 ： ' + dataApplication.plan.guests + "名程度"
                ]
                :
                ['']
              }
            />
          <DataSet
              keys = {['第二希望']}
              values = {
                dataApplication.req2.length > 0 ?
                [
                  (dataApplication.venue==10 ? '大' : '小') + 'ホール　'
                  + (dataApplication.venue==10 && dataApplication.step2.mainhallAB=="a" ? '（A型：全階利用）　' : '')
                  + (dataApplication.venue==10 && dataApplication.step2.mainhallAB=="b" ? '（B型：1階のみ）　' : '')
                  + getDateStr(dataApplication.req2),
                  getRoomStr(dataApplication.step2),
                  '集客見込 ： ' + dataApplication.plan.guests + "名程度"
                ]
                :
                ['']
              }
          />
          <DataSet borderBottom
              keys = {['第三希望']}
              values = {
                dataApplication.req3.length > 0 ?
                [
                  (dataApplication.venue==10 ? '大' : '小') + 'ホール　'
                  + (dataApplication.venue==10 && dataApplication.step3.mainhallAB=="a" ? '（A型：全階利用）　' : '')
                  + (dataApplication.venue==10 && dataApplication.step3.mainhallAB=="b" ? '（B型：1階のみ）　' : '')
                  + getDateStr(dataApplication.req3),
                  getRoomStr(dataApplication.step3),
                  '集客見込 ： ' + dataApplication.plan.guests + "名程度"
                ]
                :
                ['']
              }
          />
          </>
          :
          <>
          <DataSet
              keys = {['第一希望']}
              values = {dataApplication.req1.date ? [
                getDataStr(dataApplication.req1 , dataApplication),
                getOptionStr(dataApplication.req1),
                getOptionGuests(dataApplication.req1)
              ] : []}
            />
          <DataSet
              keys = {['第二希望']}
              values = {dataApplication.req2.date ? [
                getDataStr(dataApplication.req2 , dataApplication),
                getOptionStr(dataApplication.req2),
                getOptionGuests(dataApplication.req2)
              ] : []}
            />
          <DataSet borderBottom
              keys = {['第三希望']}
              values = {dataApplication.req3.date ? [
                getDataStr(dataApplication.req3 , dataApplication),
                getOptionStr(dataApplication.req3),
                getOptionGuests(dataApplication.req3)
              ] : []}
            />
          </>
          }
  
  
          <Text style={styles.h6}>【申請者情報】</Text>
          <DataSet
            keys = {['団体名']}
            values = {[dataApplication.apply.r_name]}
          />
          <DataSet
            keys = {['代表者名']}
            values = {[dataApplication.apply.r_d_l_name + ' ' + dataApplication.apply.r_d_f_name]}
          />
          <DataSet
            keys = {['代表者住所']}
            values = {['郵便番号 ' + dataApplication.apply.r_d_zipcode, dataApplication.apply.r_d_address]}
          />
          <DataSet 
            keys = {['電話番号1']}
            values = {[
              dataApplication.apply.r_d_tel1,
              (dataApplication.apply.r_d_tel1a ? dataApplication.apply.r_d_tel1a + '-' + dataApplication.apply.r_d_tel1b + '-' + dataApplication.apply.r_d_tel1c : '') 

            ]}
          />
          <DataSet
            keys = {['電話番号2']}
            values = {[
              dataApplication.apply.r_d_tel2,
              (dataApplication.apply.r_d_tel2a ? dataApplication.apply.r_d_tel2a + '-' + dataApplication.apply.r_d_tel2b + '-' + dataApplication.apply.r_d_tel2c : '') 
            ]}
          />
          <DataSet borderBottom
            keys = {['FAX']}
            values = {[
              dataApplication.apply.r_d_fax,
              (dataApplication.apply.r_d_faxa ? dataApplication.apply.r_d_faxa + '-' + dataApplication.apply.r_d_faxb + '-' + dataApplication.apply.r_d_faxc : '') 

            ]}
          />
  
          {!dataApplication.apply.check1.includes("no") &&
          <>
          <Text style={styles.h6}>【担当者情報】</Text>
          <DataSet
            keys = {['担当者名']}
            values = {[dataApplication.apply.r_t_l_name + ' ' + dataApplication.apply.r_t_f_name]}
          />
          <DataSet
            keys = {['担当者名フリガナ']}
            values = {[dataApplication.apply.r_t_l_kana + ' ' + dataApplication.apply.r_t_f_kana]}
          />
          {!dataApplication.apply.check2.includes("no") &&
          <DataSet
            keys = {['担当者ご住所']}
            values = {['郵便番号 ' + dataApplication.apply.r_t_zipcode, dataApplication.apply.r_t_address]}
          />
          }
          <DataSet
            keys = {['電話番号1']}
            values = {
              [
                dataApplication.apply.r_t_tel1,
                (dataApplication.apply.r_t_tel1a ? dataApplication.apply.r_t_tel1a + '-' + dataApplication.apply.r_t_tel1b + '-' + dataApplication.apply.r_t_tel1c : '') 
              ]
            }
          />
          <DataSet
            keys = {['電話番号2']}
            values = {[
              dataApplication.apply.r_t_tel2,
              (dataApplication.apply.r_t_tel2a ? dataApplication.apply.r_t_tel2a + '-' + dataApplication.apply.r_t_tel2b + '-' + dataApplication.apply.r_t_tel2c : '') 

            ]}
          />
          <DataSet
            keys = {['FAX']}
            values = {[
              dataApplication.apply.r_t_fax,
              (dataApplication.apply.r_t_faxa ? dataApplication.apply.r_t_faxa + '-' + dataApplication.apply.r_t_faxb + '-' + dataApplication.apply.r_t_faxc : '') 

            ]}
          />
          <DataSet borderBottom
            keys = {['メールアドレス']}
            values = {[dataApplication.apply.r_t_email]}
          />
          </>
          }
  
  
          {dataApplication.apply.check3.includes("yes") && 
          <>
          <Text style={styles.h6}>【事務担当者情報】</Text>
          <DataSet
            keys = {['団体名']}
            values = {[dataApplication.apply.j_name]}
          />
          <DataSet
            keys = {['代表者名']}
            values = {[dataApplication.apply.j_d_l_name + ' ' + dataApplication.apply.j_d_f_name]}
          />
          <DataSet
            keys = {['代表者名フリガナ']}
            values = {[dataApplication.apply.j_d_l_kana + ' ' + dataApplication.apply.j_d_f_kana]}
          />
          <DataSet
            keys = {['担当者名']}
            values = {[dataApplication.apply.j_t_l_name + ' ' + dataApplication.apply.j_t_f_name]}
          />
          <DataSet
            keys = {['担当者名フリガナ']}
            values = {[dataApplication.apply.j_t_l_kana + ' ' + dataApplication.apply.j_t_f_kana]}
          />
          <DataSet
            keys = {['ご住所']}
            values = {['郵便番号 ' + dataApplication.apply.j_zipcode, dataApplication.apply.j_address]}
          />
          <DataSet
            keys = {['電話番号1']}
            values = {[
              dataApplication.apply.j_tel1,
              (dataApplication.apply.j_tel1a ? dataApplication.apply.j_tel1a + '-' + dataApplication.apply.j_tel1b + '-' + dataApplication.apply.j_tel1c : '') 
            ]}
          />
          <DataSet
            keys = {['電話番号2']}
            values = {[
              dataApplication.apply.j_tel2,
              (dataApplication.apply.j_tel2a ? dataApplication.apply.j_tel2a + '-' + dataApplication.apply.j_tel2b + '-' + dataApplication.apply.j_tel2c : '') 
            ]}
          />
          <DataSet
            keys = {['FAX']}
            values = {[
              dataApplication.apply.j_fax,
              (dataApplication.apply.j_faxa ? dataApplication.apply.j_faxa + '-' + dataApplication.apply.j_faxb + '-' + dataApplication.apply.j_faxc : '') 
            ]}
          />
          <DataSet borderBottom
            keys = {['メールアドレス']}
            values = {[dataApplication.apply.j_email]}
          />
          </>
          }
  
        </View>
      </Page>
  
  
      <Page size="A4">
        <View style={styles.section}>
          <Text style={styles.h6}>【公演計画情報】</Text>
          <DataSet
            keys = {['主催者の概要']}
            values = {[
              (dataApplication.plan.dtype=="1" ? "行政" : '')
              + (dataApplication.plan.dtype=="2" ? "楽団" : '')
              + (dataApplication.plan.dtype=="3" ? "音楽事務所" : '')
              + (dataApplication.plan.dtype=="4" ? "アマチュア団体" : '')
              + (dataApplication.plan.dtype=="5" ? "個人" : '')
              + (dataApplication.plan.dtype=="6" ? "NPO" : '')
              + (dataApplication.plan.dtype=="7" ? "法人" : '')
              + (dataApplication.plan.dtype=="8" ? "その他" : '')
              + (dataApplication.plan.dtype=="9" ? "教育機関" : '')
              +  ' '
              +(
                dataApplication.plan.dtype=="9" ?
                 (
                  (dataApplication.plan.dtype1=="1" ? "公立（横浜市立）" : '')
                  + (dataApplication.plan.dtype1=="2" ? "公立（県立）" : '')
                  + (dataApplication.plan.dtype1=="3" ? "公立（他）" : '')
                  + (dataApplication.plan.dtype1=="4" ? "私立" : '')
                 )
                :
                ''
              )
              +  ' '
              +(
                dataApplication.plan.dtype=="9" ?
                 (
                  (dataApplication.plan.dtype2=="1" ? "幼稚園" : '')
                  + (dataApplication.plan.dtype2=="2" ? "小学校" : '')
                  + (dataApplication.plan.dtype2=="3" ? "中学校" : '')
                  + (dataApplication.plan.dtype2=="4" ? "高校" : '')
                  + (dataApplication.plan.dtype2=="5" ? "大学" : '')
                  + (dataApplication.plan.dtype2=="6" ? "その他学校" : '')
                 )
                :
                ''
              )
            ]}
          />
          <DataSet
            keys = {['当ホール使用履歴']}
            values = {[
              (dataApplication.plan.history==1 ? "初申請" : '')
              + (dataApplication.plan.history==2 ? "申請は2回目以上だが、主催としての利用実績無し" : '')
              + (dataApplication.plan.history==3 ? "主催として同団体で利用実績あり" : '')
              + (
                dataApplication.plan.history==3 ?
                ' '
                + (dataApplication.plan.history1==1 ? "(1回)" : '')
                + (dataApplication.plan.history1==2 ? "(2回)" : '')
                + (dataApplication.plan.history1==3 ? "(3回以上)" : '')
                :
                ''
              ),
              dataApplication.plan.file ? '初申請の場合、団体の活動履歴・開催希望公演の実績ファイルあり' : '',
              dataApplication.plan.past ==1 ? '他主催公演に同団体として出演実績あり ' +  dataApplication.plan.pastText : '',
              " ",
              '【他ホールでの公演実績】',
              ...getText(dataApplication.plan.pastOther, 'text')
            ]}
          />
          <DataSet
            keys = {['公演名']}
            values = {[dataApplication.plan.title]}
          />
          <DataSet
            keys = {['入場料金等の最高額']}
            values = {[
              (dataApplication.venue==10 ? 
              (
                (dataApplication.plan.price==1 ? "無料～2,000円" : '')
                + (dataApplication.plan.price==2 ? "2,001円～5,000円" : '')
                + (dataApplication.plan.price==3 ? "5,001円以上" : '')
              )
              :
              ''),
              (dataApplication.venue==20 ? 
              (
                (dataApplication.plan.price==1 ? "無料～3,000円" : '')
                + (dataApplication.plan.price==2 ? "3,001円以上" : '')
              )
              :
              ''),
            ]}
          />
          <DataSet
            keys = {['対象入場者']}
            values = {[
              (dataApplication.plan.target=="1" ? "一般" : '')
              + (dataApplication.plan.target=="2" ? "参加者のみ" : '')
              + (dataApplication.plan.target=="3" ? "関係者のみ" : '')
            ]}
          />
          <DataSet
            keys = {['集客見込み']}
            values = {[
              dataApplication.plan.guests + "名程度"
            ]}
          />
          <DataSet
            keys = {['入場方式']}
            values = {[
              (dataApplication.plan.ticket=="1" ? "指定席" : '')
              + (dataApplication.plan.ticket=="2" ? "当日指定引換" : '')
              + (dataApplication.plan.ticket=="3" ? "自由席（チケット発行あり）" : '')
              + (dataApplication.plan.ticket=="4" ? "自由席（チケット発行なし）" : '')
            ]}
          />
          <DataSet
            keys = {['出演者数']}
            values = {[dataApplication.plan.players + '名']}
          />
          <DataSet
            keys = {['出演者/演奏団体']}
            values = {getText(dataApplication.plan.player, 'text')}
          />
          <DataSet
            keys = {['予定曲目']}
            values = {getText(dataApplication.plan.programs, 'text')}
          />
          <DataSet
            keys = {['公演ジャンル']}
            values = {[
              (dataApplication.plan.genre=="1" ? "クラシック音楽" : '')
              + (dataApplication.plan.genre=="2" ? "ポピュラー音楽" : '')
              + (dataApplication.plan.genre=="3" ? "その他の音楽" : '')
            ]}
          />
          <DataSet
            keys = {['公演の概要']}
            values = {[
              '【使用楽器等】',
              (dataApplication.plan.part.includes("1") ? "声楽" : ''),
              (dataApplication.plan.part.includes("2") ? "弦楽器" : ''),
              (dataApplication.plan.part.includes("3") ? "管楽器" : ''),
              (dataApplication.plan.part.includes("4") ? "鍵盤楽器" : ''),
              (dataApplication.plan.part.includes("5") ? "打楽器" : ''),
              (dataApplication.plan.part.includes("6") ? 
              "合唱 " 
              + (dataApplication.plan.part6=="1" ? "(女声)" : '')
              + (dataApplication.plan.part6=="2" ? "(男声)" : '')
              + (dataApplication.plan.part6=="3" ? "(混声)" : '')
              + (dataApplication.plan.part6=="4" ? "(児童)" : '')
              :
               ''
              ),
              " ",
              '【実施体制】',
              (dataApplication.plan.type=="1" ? "1団体" : ''),
              (dataApplication.plan.type=="2" ?
                "その他 " + dataApplication.plan.type2
                :
                ''
              ),
              (dataApplication.plan.type=="3" ? "複数団体（教室）合同" : ''),
              (dataApplication.plan.type3.replace(/\r/,'')),
              " ",
              '【編成】',
              (dataApplication.plan.form=="1" ? "ソロ" : '')
              + (dataApplication.plan.form=="2" ? "アンサンブル" : '')
              + (dataApplication.plan.form=="3" ? "オーケストラ・吹奏楽・合唱（80名未満）" : '')
              + (dataApplication.plan.form=="4" ? "オーケストラ・吹奏楽・合唱（80名以上）" : ''),
              " ",
              '【内容】',
              (dataApplication.plan.program=="1" ? "演奏会" : '')
              + (dataApplication.plan.program=="2" ? "フェスティバル・大会" : '')
              + (dataApplication.plan.program=="3" ? "その他" : '')
              + (dataApplication.plan.programText ? dataApplication.plan.programText : '')
              + (dataApplication.plan.program=="4" ? "発表会" : ''),
  
              (dataApplication.plan.recital.includes("1") ? "  幼児" : ''),
              (dataApplication.plan.recital.includes("2") ? "  小中学生" : ''),
              (dataApplication.plan.recital.includes("3") ? "  高校生" : ''),
              (dataApplication.plan.recital.includes("4") ? "  大学〜大学院生" : ''),
              (dataApplication.plan.recital.includes("5") ? "  成年" : ''),
              " ",
              (dataApplication.plan.pa=="1" ? "PAなし" : ''),
              (dataApplication.plan.pa=="2" ? "PAあり（主催者業者手配あり）" : ''),
              (dataApplication.plan.pa=="3" ? "PAあり（主催者業者手配なし）" : ''),
      
              (dataApplication.plan.lighting=="1" ? "照明演出なし" : ''),
              (dataApplication.plan.lighting=="2" ? "照明演出あり（主催者業者手配あり）" : ''),
              (dataApplication.plan.lighting=="3" ? "照明演出あり（主催者業者手配なし）" : ''),
  
            ]}
          />
          <DataSet
            keys = {['ホール所有楽器の','使用希望']}
            values = {[
              (dataApplication.plan.piano==1 ?
                'フルコンサートグランドピアノ '
                + (dataApplication.plan.piano_num=="1" ? "1台" : '')
                + (dataApplication.plan.piano_num=="2" ? "2台" : '')
                :
                ''
              ),
              (dataApplication.plan.piano==1 ?
                '1台目 '
                + (dataApplication.plan.piano1=="0" ? "機種指定なし" : '')
                + (dataApplication.plan.piano1=="1" ? "スタインウェイD274" : '')
                + (dataApplication.plan.piano1=="2" ? "ベーゼンドルファーインペリアル" : '')
                + (dataApplication.plan.piano1=="3" ? "ヤマハCFX" : '')
                :
                ''
              ),
              (dataApplication.plan.piano==1 && dataApplication.plan.piano_num=="2" ?
                '2台目 '
                + (dataApplication.plan.piano2=="0" ? "機種指定なし" : '')
                + (dataApplication.plan.piano2=="1" ? "スタインウェイD274" : '')
                + (dataApplication.plan.piano2=="2" ? "ベーゼンドルファーインペリアル" : '')
                + (dataApplication.plan.piano2=="3" ? "ヤマハCFX" : '')
                : 
                ''
              ),
  
              (dataApplication.plan.organ==1 ?
                  'パイプオルガン '
                  + (dataApplication.plan.organist=="1" ? "オルガニスト確定" : '')
                  + (dataApplication.plan.organist=="2" ? "オルガニスト未定" : '')
                  + (dataApplication.plan.organist=="3" ? "オルガニストの斡旋希望" : '')
          
                  + (dataApplication.plan.organist=="1" ?
                     ' ' + dataApplication.plan.organistname
                     :
                     ''
                  )
              : '')
            ]}
          />
          <DataSet 
            keys = {['抽選会への参加']}
            values = {[
              (dataApplication.plan.lottery=="0" ? "希望しません（ホール代行抽選）" : ''),
              (dataApplication.plan.lottery=="1" ? "希望します（来館抽選）" : '')
            ]}
          />
          <DataSet borderBottom
            keys = {['備考メモ欄']}
            values = {[dataApplication.plan.comment.replace(/\r/,'')]}
          />
  
  
          {dataApplication.appnum
          ?
          <>
          <DataSet style={{marginTop: '2mm'}}
            keys = {['申し込み番号']}
            values = {[dataApplication.appnum]}
          />
          <DataSet borderBottom
            keys = {['申し込み日']}
            values = {[dataApplication.cdatetime]}
          />
          </>
          :
          <>
          <DataSet style={{marginTop: '2mm'}}
            keys = {['申し込み番号']}
            values = {[dataApplication.no]}
          />
          <DataSet
            keys = {['申し込み日']}
            values = {[dataApplication.cdatetime]}
          />
          <DataSet
            keys = {['最終更新日']}
            values = {[dataApplication.mdatetime]}
          />
          <DataSet borderBottom
            keys = {['抽選結果']}
            values = {[
              (dataApplication.win == 11 ? '第一希望当選' : '') +
              (dataApplication.win == 12 ? '第二希望当選' : '') +
              (dataApplication.win == 13 ? '第三希望当選' : '') +
              (dataApplication.win == 14 ? '落選' : '')
            ]}
          />
          </>
          }
  
  
  
        </View>
      </Page>
    </Document>
        );
  } else {
    return (
      <Document>
        <Page size="A4" style={styles.page}>
        </Page>
      </Document>
    );
  }
}
