import { Button } from "@mui/material";
import { React, useEffect,useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { useMyData } from './MyProvider';

export default function ApplyLog() {
  const { getApplicationsLog,dataApplicationsRsv} = useMyData();
  
  const [pages, setPages] = useState([]);
  const [rsvData, setRsvData] = useState([]);

  const params = useParams();
  const page = params.page || 1;

  const pp = 20;

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    getApplicationsLog();
  }, []);

  useEffect(() => {
    let d = [];
    if(dataApplicationsRsv.data){
      dataApplicationsRsv.data.forEach((e) => {
        let venue = '';
        if(e.venue==10){
          venue = '大ホール';
        }
        if(e.venue==20){
          venue = '小ホール';
        }

        let date1 = {};
        let date2 = {};
        let date3 = {};

        if(e.date1.date) {
          const d = e.date1;
          let times = [];
        
          if (d.time1 > 0) {
            times.push('午前');
          }
          if (d.time2 > 0) {
            times.push('午後');
          }
          if (d.time3 > 0) {
            times.push('夜間');
          }
          date1 = {
            date: d.date_t,
            time: times.join('・'),
          }
        } else {
          date1 = {
            date: '希望なし',
            time: '',
          }
        }

        if(e.date2.date) {
          const d = e.date2;
          let times = [];
        
          if (d.time1 > 0) {
            times.push('午前');
          }
          if (d.time2 > 0) {
            times.push('午後');
          }
          if (d.time3 > 0) {
            times.push('夜間');
          }
          date2 = {
            date: d.date_t,
            time: times.join('・'),
          }
        } else {
          date2 = {
            date: '希望なし',
            time: '',
          }
        }

        if(e.date3.date) {
          const d = e.date3;
          let times = [];
        
          if (d.time1 > 0) {
            times.push('午前');
          }
          if (d.time2 > 0) {
            times.push('午後');
          }
          if (d.time3 > 0) {
            times.push('夜間');
          }
          date3 = {
            date: d.date_t,
            time: times.join('・'),
          }
        } else {
          date3 = {
            date: '希望なし',
            time: '',
          }
        }

        d.push({
          id: e.id,
          no: e.no,
          venue: venue,
          date1: date1,
          date2: date2,
          date3: date3,
          v4: e.v4,
          win: e.win,
        });
      });

      setRsvData(d);
      if(d.length > 0) {
        const thisD = d.slice((pp * (page - 1)), (pp * page));
        setRsvData([...thisD])
        let pagesCalc = [];
        let i=1;
        while(1){
          pagesCalc.push(i);
          if(i * pp >= d.length) {
            break;
          }
          i++;
        }
        setPages(pagesCalc);
      }

    }
  }, [dataApplicationsRsv,page]);

  const scrollTop = (e) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  return (
    <>
      <h1 className="pagetopLabel">大小ホール 抽選履歴</h1>
      <ul className="s-reservelist">
        {rsvData.map((item) => (
          <li key={item.id}>
            <span className="date">{item.no}</span>
            <span className="room">{item.venue} </span>
            <span className="date1">第一希望［{item.date1.date}］</span>
            <span className="date1">第二希望［{item.date2.date}］</span>
            <span className="date1">第三希望［{item.date3.date}］</span>
            {item.win == 11 && '第一希望当選'}
            {item.win == 12 && '第二希望当選'}
            {item.win == 13 && '第三希望当選'}
            {item.win == 14 && '落選'}
            <Button variant="outlined" value={item.id} component={Link} to={"/apply/"+item.v4} className="cancel">詳細</Button>
          </li>
        ))}
        {rsvData.length > 0 &&
        <ul className="pager">
          {page > 1 &&
            <li className="prev">
              <Link to={'/apply_log/' + (page - 1)} onClick={scrollTop}>««</Link>
            </li>
          }
          {pages.map((item) => (
            <li key={item}>
              { (item == page) ?
              <span>{item}</span>
              :
              <Link to={'/apply_log/' + item} onClick={scrollTop}>{item}</Link>
              }
            </li>
          ))}
          {[...pages].reverse()[0] != page &&
            <li className="next">
              <Link to={'/apply_log/' + (1 + parseInt(page))} onClick={scrollTop}>»»</Link>
            </li>
          }
        </ul>
        }
        {rsvData.length===0 && <li>抽選履歴はありません。</li>}
      </ul>

      <div className="Form__btnbox">
        <div className="Form__btn-return">
          <Link to="/">マイページTOPへ</Link>
        </div>
      </div>

    </>
  );
}
