import { React, useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useMyData } from './MyProvider';
import { useInput,useRadio } from "./hooks";
import ApplicationAddReqs from "./ApplicationAddReqs";
import ApplicationAddCal from "./ApplicationAddCal";


export default function ApplicationAddStep() {
  let {id} = useParams();

  const { data,dataApplication,setDataApplication } = useMyData();

  const [step1, setStep1] = useState("");
  const [step2, setStep2] = useState("");
  const [step3, setStep3] = useState("");

  const [timea, setTimea] = useState(false);
  const [timeb, setTimeb] = useState(false);
  const [timec, setTimec] = useState(false);

  const [months, setMonths] = useState([]);

  const [mainhallAB,,setMainhallAB] = useRadio(dataApplication.mode ? dataApplication[dataApplication.mode].mainhallAB || "a" : "a"); // 利用施設
  const [kubunA,,setKubunA] = useInput(dataApplication && dataApplication.mode ? dataApplication[dataApplication.mode].kubunA || 1 : 1); // 利用区分 午前
  const [kubunB,,setKubunB] = useInput(dataApplication && dataApplication.mode ? dataApplication[dataApplication.mode].kubunB || 1 : 1); // 利用区分 午後
  const [kubunC,,setKubunC] = useInput(dataApplication && dataApplication.mode ? dataApplication[dataApplication.mode].kubunC || 1 : 1); // 利用区分 夜間

  const [openTime,,setOpenTime] = useInput(dataApplication.mode ? dataApplication[dataApplication.mode].openTime || "" : ""); // 開場時間
  const [startTime,,setStartTime] = useInput(dataApplication.mode ? dataApplication[dataApplication.mode].startTime || "" : ""); // 開演時間
  const [endTime,,setEndTime] = useInput(dataApplication.mode ? dataApplication[dataApplication.mode].endTime || "" : ""); // 終演時間
  const [exitTime,,setExitTime] = useInput(dataApplication.mode ? dataApplication[dataApplication.mode].exitTime || "" : ""); // 退館時間

  const [encho,,setEncho] = useRadio(dataApplication.mode ? dataApplication[dataApplication.mode].encho || 0 : 0); // 延長有無
  const [enchoBefore,,setEnchoBefore] = useInput(dataApplication.mode ? dataApplication[dataApplication.mode].enchoBefore || 0 : 0); // 利用時間前延長
  const [enchoAfter,,setEnchoAfter] = useInput(dataApplication.mode ? dataApplication[dataApplication.mode].enchoAfter || 0 : 0); // 利用時間後延長

  const [optionRoom, setOptionRoom] = useState(dataApplication.mode ? dataApplication[dataApplication.mode].optionRoom||[] : []); // 併設利用

  const [targetProps] = useRadio(dataApplication.plan.target || 1); // 対象入場者
  const [guestsProps] = useInput(dataApplication.plan.guests||"","number"); // 集客見込み
  const [ticketProps] = useRadio(dataApplication.plan.ticket || 1); // 入場方式

  const [hasError, setHasError] = useState(true); // エラーチェック
  const [kubunError, setKubunError] = useState(0); // 区分エラー
  const [openError, setOpenError] = useState(0); // 開場時刻エラー
  const [startError, setStartError] = useState(false); // 開演時刻エラー
  const [endError, setEndError] = useState(false); // 終演時刻エラー
  const [exitError, setExitError] = useState(false); // 退館時刻エラー
  const [guestsError, setGuestsError] = useState(0); // 入場見込みエラー
  const [openMinTime, setOpenMinTime] = useState(0); // 会場から開演までの最小時間
  
  const [maxSheet, setMaxSheet] = useState(0); // 席数
  const [maxFreeSheet, setMaxFreeSheet] = useState(0); // 自由席最大席数

  useEffect(()=>{
    console.log(dataApplication);
    let ms = new Array();
    let month = new Date(dataApplication.tsdate);
    month.setDate(1);
    let tedate = new Date(dataApplication.tedate);
    while(month <= tedate) {
      ms.push ( new Date( month.getTime() ) );
      month.setMonth( month.getMonth() + 1);
    }
    setMonths(ms);

    let req = [];
    if ( dataApplication.mode === "step1") {
      req = dataApplication.req1;
    }
    if ( dataApplication.mode === "step2") {
      req = dataApplication.req2;
    }
    if ( dataApplication.mode === "step3") {
      req = dataApplication.req3;
    }
    setTimea(false);
    setTimeb(false);
    setTimec(false);

    req.forEach(function(data){
      if ( data.substr(-1) === 'a' ) {
        setTimea(true);
      }
      if ( data.substr(-1) === 'b' ) {
        setTimeb(true);
      }
      if ( data.substr(-1) === 'c' ) {
        setTimec(true);
      }
    });

  },[dataApplication]);

  useEffect(()=>{
    if ( typeof dataApplication === 'undefined' || typeof dataApplication.mode === 'undefined' ) {
      setDataApplication({
        ...dataApplication,
        "mode": "step1",
      })
    }
    if ( dataApplication.mode==="step1" ) {
      setStep1("active");
    } else if ( dataApplication.mode==="step2" ) {
      setStep1("complete");
      setStep2("active");
    } else if ( dataApplication.mode==="step3" ) {
      setStep1("complete");
      setStep2("complete");
      setStep3("active");
    }

  },[]);

  useEffect(() => {
    if(dataApplication.venue==20){
      setEnchoBefore(0);
    }
  },[timea]);
  useEffect(() => {
    if(dataApplication.venue==20){
      setEnchoAfter(0);
    }
  },[timec]);

  const optionRoomAdd = () => {
    let id = 1;
    if(optionRoom.length) {
      id = optionRoom.slice(-1)[0].id + 1;
    }
    let room = 4;
    if(dataApplication.venue==20){
      room = 5;
    }else{
      if(optionRoom.filter(item => item.room == 4).length == 0){
        room = 4;
      } else if(optionRoom.filter(item => item.room == 3).length == 0){
        room = 3;
      } else if(optionRoom.filter(item => item.room == 2).length == 0){
        room = 2;
      }
    }

    while(
      optionRoom.filter(item => item.room == room).length ||
      (
        (room==1) ||
        (dataApplication.venue==20 && room==2)
        )
    ){
      room++;
    };
    if(room==11) {
      if(dataApplication.venue==20){
        if(optionRoom.filter(item => item.room == 4).length == 0){
          room = 4;
        } else if(optionRoom.filter(item => item.room == 3).length == 0){
          room = 3;
        }
      } else {
        if(optionRoom.filter(item => item.room == 4).length == 0){
          room = 4;
        } else if(optionRoom.filter(item => item.room == 3).length == 0){
          room = 3;
        } else if(optionRoom.filter(item => item.room == 2).length == 0){
          room = 2;
        }
      }
    }

    if(room <= 10) {
      setOptionRoom([
        ...optionRoom,
        {
          id,
          room,
          time:1,
        }
      ]);
    }

  }
  const optionRoomDel = (e) => {
    setOptionRoom(optionRoom.filter(item => item.id != e.currentTarget.value));
  }

  const roomUpdate = (e) => {
    const room = e.currentTarget.value;
    const id = e.currentTarget.dataset.room;

    if (typeof room === 'string') {

      if (id) {
        const newarray = optionRoom.map(item => {
          if (item.id == id ) {
            return {
              ...item,
              room
            };
          } else {
            return item;
          }
        })
        setOptionRoom(newarray);
      }
    }
  }
  const roomTimeUpdate = (e) => {
    const time = e.currentTarget.value;
    const id = e.currentTarget.dataset.room;

    if (typeof time === 'string') {

      if (id) {
        const newarray = optionRoom.map(item => {
          if (item.id == id ) {
            return {
              ...item,
              time,
            };
          } else {
            return item;
          }
        })
        setOptionRoom(newarray);
      }
    }
  }

  useEffect(()=>{
    let err = false;

    let req = [];
    if ( dataApplication.mode === "step1") {
      req = dataApplication.req1;
    }
    if ( dataApplication.mode === "step2") {
      req = dataApplication.req2;
    }
    if ( dataApplication.mode === "step3") {
      req = dataApplication.req3;
    }
    // 区分チェック
    if(timea || timeb || timec) {
      if( (timea && kubunA.value == 1) || 
          (timeb && kubunB.value == 1) ||
          (timec && kubunC.value == 1) )
      {
        if( (timea && kubunA.value == 1) && 
            (timeb && kubunB.value == 2) &&
            (timec && kubunC.value == 1) )
        {
          // 本番と本番の間にリハーサルを含むとエラー
          err=true;
          setKubunError(2);
        } else {
          // 
          setKubunError(0);
        }
      } else {
        // どこにも本番が無い場合はエラー
        err=true;
        setKubunError(1);
      }
    }

    // 開場時刻チェック
    if(openTime.value!=="") {
      const opentime = parseInt(openTime.value.replace(':',''),10);
      let minstart = 0;
      let maxstart = 0;

      if(timea) {
        minstart = 900;
        maxstart = 2200;
        if(enchoBefore.value==30){
          minstart = 830;
        }
        if(enchoBefore.value==60){
          minstart = 800;
        }
      } else if(timeb) {
        minstart = 1300;
        maxstart = 2200;
        if(enchoBefore.value==30){
          minstart = 1230;
        }
        if(enchoBefore.value==60){
          minstart = 1200;
        }
      }

      if(minstart <= opentime && opentime <= maxstart) {
        if(targetProps.val=="1" && startTime.value!==""){ // 対象入場者が一般 && 開演時刻設定あり
          const starttime = parseInt(startTime.value.replace(':',''),10);
          const opentime60 = Math.floor(opentime / 100) * 60 + (opentime % 100);
          const starttime60 = Math.floor(starttime / 100) * 60 + (starttime % 100);
          
          let durationTime = 0;
          if(dataApplication.venue==10) {
            if(mainhallAB.val=="a"){
              if(guestsProps.value > parseInt(dataApplication.mainhall_a_50seats,10)) {
                durationTime = dataApplication.mainhall_a_open_50minutes;
              } else {
                durationTime = dataApplication.mainhall_a_open_minutes;
              }
            } else {
              if(guestsProps.value > parseInt(dataApplication.mainhall_b_50seats,10)) {
                durationTime = dataApplication.mainhall_b_open_50minutes;
              } else {
                durationTime = dataApplication.mainhall_b_open_minutes;
              }
            }
          } else {
            if(guestsProps.value > parseInt(dataApplication.smallhall_50seats,10)) {
              durationTime = dataApplication.smallhall_open_50minutes;
            } else {
              durationTime = dataApplication.smallhall_open_minutes;
            }
          }
          setOpenMinTime(durationTime);

          if(starttime60 - opentime60 < durationTime) {
            setOpenError(2);
            err = true;
          } else {
            setOpenError(0);
          }

        } else {
          setOpenError(0);
        }
      } else {
        setOpenError(1);
        err = true;
      }
    } else {
      setOpenError(0);
    }

    // 開演時刻チェック
    if(openTime.value!=="" && startTime.value!=="") {
      const opentime = parseInt(openTime.value.replace(':',''),10);
      const starttime = parseInt(startTime.value.replace(':',''),10);
      if(opentime < starttime) {
        let minend = 0;
        let maxend = 0;
        if(timea && timeb && !timec && kubunA.value==1 && kubunB.value==2){
          // ケースA 午前午後 / 午前本番 午後RH
          minend = 900;
          maxend = 1200;
        } else if(timea && timeb && !timec && kubunA.value==2 && kubunB.value==1){
          // ケースB 午前午後 / 午前RH 午後本番
          minend = 1201;
          maxend = 1630;
        } else if(timea && timeb && !timec && kubunA.value==1 && kubunB.value==1){
          // ケースC 午前午後 / 午前本番 午後本番
          minend = 900;
          maxend = 1200;
        } else if(!timea && timeb && timec && kubunB.value==1 && kubunC.value==2){
          // ケースD 午後夜間 / 午後本番 夜間RH
          minend = 1300;
          maxend = 1630;
        } else if(!timea && timeb && timec && kubunB.value==2 && kubunC.value==1){
          // ケースE 午後夜間 / 午後RH 夜間本番
          minend = 1631;
          maxend = 2200;
        } else if(!timea && timeb && timec && kubunB.value==1 && kubunC.value==1){
          // ケースF 午後夜間 / 午後本番 夜間本番
          minend = 1300;
          maxend = 1630;
        } else if(timea && timeb && timec && kubunA.value==1 && kubunB.value==2 && kubunC.value==2){
          // ケースG 終日 / 午前本番 午後RH 夜間RH
          minend = 900;
          maxend = 1200;
        } else if(timea && timeb && timec && kubunA.value==2 && kubunB.value==1 && kubunC.value==2){
          // ケースH 終日 / 午前RH 午後本番 夜間RH
          minend = 1201;
          maxend = 1630;
        } else if(timea && timeb && timec && kubunA.value==2 && kubunB.value==2 && kubunC.value==1){
          // ケースI 終日 / 午前RH 午後RH 夜間本番
          minend = 1631;
          maxend = 2200;
        } else if(timea && timeb && timec && kubunA.value==1 && kubunB.value==1 && kubunC.value==2){
          // ケースJ 終日 / 午前本番 午後本番 夜間RH
          minend = 900;
          maxend = 1200;
        } else if(timea && timeb && timec && kubunA.value==2 && kubunB.value==1 && kubunC.value==1){
          // ケースK 終日 / 午前RH 午後本番 夜間本番
          minend = 1201;
          maxend = 1630;
        } else if(timea && timeb && timec && kubunA.value==1 && kubunB.value==1 && kubunC.value==1){
          // ケースL 終日 / 午前本番 午後本番 夜間本番
          minend = 900;
          maxend = 1200;
        }
        if(minend <= starttime && starttime <= maxend) {
          setStartError(false);
        } else {
          setStartError(true);
          err = true;
        }
      } else {
        setStartError(true);
        err = true;
      }
    } else {
      setStartError(false);
    }

    // 終演時刻チェック
    if(startTime.value!=="" && endTime.value!=="") {
      const starttime = parseInt(startTime.value.replace(':',''),10);
      const endtime = parseInt(endTime.value.replace(':',''),10);
      if(starttime < endtime) {
        let minend = 0;
        let maxend = 0;
        if(timea && timeb && !timec && kubunA.value==1 && kubunB.value==2){
          // ケースA 午前午後 / 午前本番 午後RH
          minend = 900;
          maxend = 1300;
        } else if(timea && timeb && !timec && kubunA.value==2 && kubunB.value==1){
          // ケースB 午前午後 / 午前RH 午後本番
          minend = 1300;
          maxend = 1630;
        } else if(timea && timeb && !timec && kubunA.value==1 && kubunB.value==1){
          // ケースC 午前午後 / 午前本番 午後本番
          minend = 1301;
          maxend = 1630;
        } else if(!timea && timeb && timec && kubunB.value==1 && kubunC.value==2){
          // ケースD 午後夜間 / 午後本番 夜間RH
          minend = 1300;
          maxend = 1730;
        } else if(!timea && timeb && timec && kubunB.value==2 && kubunC.value==1){
          // ケースE 午後夜間 / 午後RH 夜間本番
          minend = 1730;
          maxend = 2200;
        } else if(!timea && timeb && timec && kubunB.value==1 && kubunC.value==1){
          // ケースF 午後夜間 / 午後本番 夜間本番
          minend = 1731;
          maxend = 2200;
        } else if(timea && timeb && timec && kubunA.value==1 && kubunB.value==2 && kubunC.value==2){
          // ケースG 終日 / 午前本番 午後RH 夜間RH
          minend = 900;
          maxend = 1300;
        } else if(timea && timeb && timec && kubunA.value==2 && kubunB.value==1 && kubunC.value==2){
          // ケースH 終日 / 午前RH 午後本番 夜間RH
          minend = 1300;
          maxend = 1730;
        } else if(timea && timeb && timec && kubunA.value==2 && kubunB.value==2 && kubunC.value==1){
          // ケースI 終日 / 午前RH 午後RH 夜間本番
          minend = 1730;
          maxend = 2200;
        } else if(timea && timeb && timec && kubunA.value==1 && kubunB.value==1 && kubunC.value==2){
          // ケースJ 終日 / 午前本番 午後本番 夜間RH
          minend = 1300;
          maxend = 1730;
        } else if(timea && timeb && timec && kubunA.value==2 && kubunB.value==1 && kubunC.value==1){
          // ケースK 終日 / 午前RH 午後本番 夜間本番
          minend = 1300;
          maxend = 2200;
        } else if(timea && timeb && timec && kubunA.value==1 && kubunB.value==1 && kubunC.value==1){
          // ケースL 終日 / 午前本番 午後本番 夜間本番
          minend = 1730;
          maxend = 2200;
        }
        if(minend <= endtime && endtime <= maxend) {
          setEndError(false);
        } else {
          setEndError(true);
          err = true;
        }
      } else {
        setEndError(true);
        err = true;
      }
    } else {
      setEndError(false);
    }

    // 退館時刻チェック
    if(endTime.value!=="" && exitTime.value!=="") {
      const endtime = parseInt(endTime.value.replace(':',''),10);
      const exittime = parseInt(exitTime.value.replace(':',''),10);
      if(endtime < exittime) {
        let minend = 0;
        let maxend = 0;

        if(timec) {
          minend = 1730;
          maxend = 2200;
          if(enchoAfter.value==30){
            maxend = 2230;
          }
          if(enchoAfter.value==60){
            maxend = 2300;
          }
        } else if(timeb) {
          minend = 1300;
          maxend = 1630;
          if(enchoAfter.value==30){
            maxend = 1700;
          }
          if(enchoAfter.value==60){
            maxend = 1730;
          }
        }

        if(minend <= exittime && exittime <= maxend) {
          setExitError(false);
        } else {
          setExitError(true);
          err = true;
        }
      } else {
        setExitError(true);
        err = true;
      }
    } else {
      setExitError(false);
    }



    // 入場見込みチェック
    let maxsheet = 0;
    if(dataApplication.venue==10) {
      if(mainhallAB.val==="a") {
        maxsheet = parseInt(dataApplication.mainhall_a_allseats, 10);
      } else {
        maxsheet = parseInt(dataApplication.mainhall_b_allseats, 10);
      }
    } else {
      maxsheet = parseInt(dataApplication.smallhall_allseats, 10);
    }
    setMaxSheet(maxsheet);
    if(parseInt(guestsProps.value,10) > maxsheet) {
      // 定員オーバー
      err=true;
      setGuestsError(1);
    } else {
      // 自由席の場合
      if(ticketProps.val=="3" || ticketProps.val=="4"){
        if(dataApplication.venue==10) {
          if(mainhallAB.val==="a") {
            maxsheet = parseInt(dataApplication.mainhall_a_80seats, 10);
          } else {
            maxsheet = parseInt(dataApplication.mainhall_b_80seats, 10);
          }
        } else {
          maxsheet = parseInt(dataApplication.smallhall_80seats, 10);
        }
        setMaxFreeSheet(maxsheet);
        if(parseInt(guestsProps.value,10) > maxsheet) {
          // 定員オーバー
          err=true;
          setGuestsError(2);
        } else {
          setGuestsError(0);
        }
      } else {
        setGuestsError(0);
      }

    }


    // 必須チェック
    if(parseInt(targetProps.val, 10) < 1) err=true;
    if(parseInt(guestsProps.value, 10) < 1) err=true;
    if(parseInt(ticketProps.val, 10) < 1) err=true;

    if ( dataApplication.mode === "step1") {

      if ( req.length < 2 ) err=true;


      if ( openTime.value=="" ) err=true;
      if ( startTime.value=="" ) err=true;
      if ( endTime.value=="" ) err=true;
      if ( exitTime.value=="" ) err=true;
      if ( guestsProps.value=="" ) err=true;

    } else {

      if ( req.length == 1 ) err=true;

      if( req.length > 1 ) {
        if ( openTime.value=="" ) err=true;
        if ( startTime.value=="" ) err=true;
        if ( endTime.value=="" ) err=true;
        if ( exitTime.value=="" ) err=true;
        if ( guestsProps.value=="" ) err=true;
      }
    }

    setHasError(err);

  });

  const makeData = () => {
    let data = {};

    data.mainhallAB = mainhallAB.val;
    data.kubunA = timea ? kubunA.value : 0;
    data.kubunB = timeb ? kubunB.value : 0;
    data.kubunC = timec ? kubunC.value : 0;
    data.openTime = openTime.value;
    data.startTime = startTime.value;
    data.endTime = endTime.value;
    data.exitTime = exitTime.value;
    data.encho = encho.val;
    data.enchoBefore = enchoBefore.value;
    data.enchoAfter = enchoAfter.value;
    data.optionRoom = optionRoom;

    return data;
  }

  const nextStep = () => {
    window.scrollTo({
      top: 0,
    });
    const data = makeData();

    if ( dataApplication.mode === "step1") {
      let plan = {
        ...dataApplication.plan,
        "target":targetProps.val,
        "guests":guestsProps.value,
        "ticket":ticketProps.val,
      };

      setDataApplication({
        ...dataApplication,
        "step1": data,
        "mode": "step2",
        "plan":plan,
      })
    }else if ( dataApplication.mode === "step2") {
      let nextMode = 'step3';
      if ( dataApplication.req2.length == 0 ) nextMode = 'step4';
      
      setDataApplication({
        ...dataApplication,
        "step2": data,
        "mode": nextMode,
      })
    }else if ( dataApplication.mode === "step3") {
      setDataApplication({
        ...dataApplication,
        "step3": data,
        "mode": "step4",
      })
    }
  };

  const prevStep = () => {
    window.scrollTo({
      top: 0,
    });
    const data = makeData();

    if ( dataApplication.mode === "step2") {
      setDataApplication({
        ...dataApplication,
        "step2": data,
        "mode": "step1",
      })
    }else if ( dataApplication.mode === "step3") {
      setDataApplication({
        ...dataApplication,
        "step3": data,
        "mode": "step2",
      })
    }
  };

  const copyFrom1 = () => {
    const data = dataApplication.step1;

    setMainhallAB(data.mainhallAB);
    setKubunA(data.kubunA);
    setKubunB(data.kubunB);
    setKubunC(data.kubunC);
    setOpenTime(data.openTime);
    setStartTime(data.startTime);
    setEndTime(data.endTime);
    setExitTime(data.exitTime);
    setEncho(data.encho);
    setEnchoBefore(data.enchoBefore);
    setEnchoAfter(data.enchoAfter);
    setOptionRoom(data.optionRoom);
  };

  const copyFrom2 = () => {
    const data = dataApplication.step2;

    setMainhallAB(data.mainhallAB);
    setKubunA(data.kubunA);
    setKubunB(data.kubunB);
    setKubunC(data.kubunC);
    setOpenTime(data.openTime);
    setStartTime(data.startTime);
    setEndTime(data.endTime);
    setExitTime(data.exitTime);
    setEncho(data.encho);
    setEnchoBefore(data.enchoBefore);
    setEnchoAfter(data.enchoAfter);
    setOptionRoom(data.optionRoom);
  };

  const copyFrom3 = () => {
    if ( dataApplication.mode === "step1") {
      setDataApplication({
        ...dataApplication,
        "step1": dataApplication.step3,
      })
    }
    if ( dataApplication.mode === "step2") {
      setDataApplication({
        ...dataApplication,
        "step2": dataApplication.step3,
      })
    }
    if ( dataApplication.mode === "step3") {
      setDataApplication({
        ...dataApplication,
        "step3": dataApplication.step3,
      })
    }
  };

  return (
    <>
      <h1 className="pagetopLabel">{dataApplication.venue==10 ? '大' : '小'}ホール抽選申込</h1>

      <ul className="Progressbar">
        <li className={step1}><span>第一希望選択</span></li>
        <li className={step2}><span>第二希望選択</span></li>
        <li className={step3}><span>第三希望選択</span></li>
        <li><span>利用許可<br />申請情報の入力</span></li>
        <li><span>公演計画<br />申請情報の入力</span></li>
        <li><span>確認</span></li>
        <li><span>抽選申込完了</span></li>
      </ul>

      {months.map((month) => (
        <div key={month.getTime()}>
          <ul className="CalendarMenu">
            <li className="onlymonth">{month.getFullYear()}年<span>{month.getMonth() + 1}月</span></li>
          </ul>

          <ApplicationAddCal month={month} dataApplication={dataApplication} />

          <ApplicationAddReqs month={month} dataApplication={dataApplication} />

        </div>
      ))}

      {((timea && timeb) || (timeb && timec)) &&
      <>
      <ul className="Copybox">
        { (dataApplication.mode === "step2" || dataApplication.mode === "step3")
          && <li><a onClick={copyFrom1}><i className="fas fa-copy"></i>第一希望の内容をコピー</a></li>
        }
        { (dataApplication.mode === "step3")
          && <li><a onClick={copyFrom2}><i className="fas fa-copy"></i>第二希望の内容をコピー</a></li>
        }
      </ul>
      <table className="Form" summary="#">
        <tbody>
        {dataApplication.venue==10 &&
        <tr>
          <th>利用施設 <span>※必須</span></th>
          <td>
            <label><input type="radio" {...mainhallAB} value="a" checked={mainhallAB.val=="a"} />大ホール(A型：全階利用）</label>
            <label><input type="radio" {...mainhallAB} value="b" checked={mainhallAB.val=="b"} />大ホール（B型：1階のみ）</label>
          </td>
        </tr>
        }
        <tr>
          <th>対象入場者<span>※必須</span></th>
          <td>
            <label><input type="radio" {...targetProps} value="1" checked={targetProps.val=="1"} disabled={(dataApplication.mode === "step2" || dataApplication.mode === "step3") ? 'disabled' : ''} />一般</label>
            <label><input type="radio" {...targetProps} value="2" checked={targetProps.val=="2"} disabled={(dataApplication.mode === "step2" || dataApplication.mode === "step3") ? 'disabled' : ''} />参加者のみ</label>
            <label><input type="radio" {...targetProps} value="3" checked={targetProps.val=="3"} disabled={(dataApplication.mode === "step2" || dataApplication.mode === "step3") ? 'disabled' : ''} />関係者のみ</label>
          </td>
        </tr>
        <tr>
          <th>集客見込み<span>※必須</span></th>
          <td>
            <input type="text" required size="10" {...guestsProps} disabled={(dataApplication.mode === "step2" || dataApplication.mode === "step3") ? 'disabled' : ''} />名程度
            <span>（半角数字）</span>
            <span>（定員：{maxSheet}席
              {ticketProps && (ticketProps.val=="3" || ticketProps.val=="4") && 
              <>
              　自由席最大：{maxFreeSheet}席
              </>
              }
              ）</span>
            { guestsError>0 && guestsError==1 && <p><span className="err">入場見込みが、定員を超過しています。</span></p> }
            { guestsError>0 && guestsError==2 && <p><span className="err">入場見込みが、座席数の８割程度を越える場合は、自由席を選択できません。全席指定もしくは当日指定引換を選択してください。</span></p> }
          </td>
        </tr>
        <tr>
          <th>入場方式<span>※必須</span></th>
          <td>
            <label><input type="radio" {...ticketProps} value="1" checked={ticketProps.val=="1"} disabled={(dataApplication.mode === "step2" || dataApplication.mode === "step3") ? 'disabled' : ''} />指定席</label>
            <label><input type="radio" {...ticketProps} value="2" checked={ticketProps.val=="2"} disabled={(dataApplication.mode === "step2" || dataApplication.mode === "step3") ? 'disabled' : ''} />当日指定引換</label>
            <label><input type="radio" {...ticketProps} value="3" checked={ticketProps.val=="3"} disabled={(dataApplication.mode === "step2" || dataApplication.mode === "step3") ? 'disabled' : ''} />自由席（チケット発行あり）</label>
            <label><input type="radio" {...ticketProps} value="4" checked={ticketProps.val=="4"} disabled={(dataApplication.mode === "step2" || dataApplication.mode === "step3") ? 'disabled' : ''} />自由席（チケット発行なし）</label><br />
            {dataApplication.venue==10 &&
            <span>※自由席については<a href="">開催条件</a>があります。別途ご確認ください。</span>
            }
          </td>
        </tr>
        <tr>
          <th rowSpan={2}>利用区分 <span>※必須</span></th>
          <td>
            {timea &&
            <>
            <label>午前</label>
            <select {...kubunA}>
              <option value="2">準備・リハーサル・後片付け</option>
              <option value="1">本番</option>
            </select>&nbsp;
            </>
            }

            {timeb &&
            <>
            <label>午後</label>
            <select {...kubunB}>
              <option value="2">準備・リハーサル・後片付け</option>
              <option value="1">本番</option>
            </select>&nbsp;
            </>
            }

            {timec &&
            <>
            <label>夜間</label>
            <select {...kubunC}>
              <option value="2">準備・リハーサル・後片付け</option>
              <option value="1">本番</option>
            </select>
            </>
            }
            {kubunError===1 && <p><span className="err"> 本番区分を含めてお申込みください</span></p>}
            {kubunError===2 && <p><span className="err"> 「本番」区分と「本番」区分の間に「準備・リハーサル･後片付け」を含むことはできません。</span></p>}
          </td>
        </tr>
        <tr>
          <th style={{display: 'none'}}>利用区分 </th>
          <td>
            <label><input type="radio" value="0" {...encho} checked={encho.val=="0"} />延長時間無し</label>
            <label><input type="radio" value="1" {...encho} checked={encho.val=="1"} />延長時間有り</label>

            <select disabled={encho.val=="0" || (dataApplication.venue==20 && !timea) } {...enchoBefore} >
              <option value="0">前延長なし</option>
              <option value="30">前30分</option>
              <option value="60">前1時間</option>
            </select>
            <select disabled={encho.val=="0" || (dataApplication.venue==20 && !timec) } {...enchoAfter} >
              <option value="0">後延長なし</option>
              <option value="30">後30分</option>
              <option value="60">後1時間</option>
            </select><br />
            <span>※早朝(9:00以前)または深夜(22:00以降)に、1時間以上延長したい場合はホールにご相談ください。</span>
          </td>
        </tr>
        <tr>
          <th>公演時間 <span>※必須</span></th>
          <td>
            開場<input type="time" min="10:00" max="22:00" step="300" {...openTime} required className={openTime.value ? "" : "err"} />
            開演<input type="time" min="10:00" max="22:00" step="300" {...startTime} required className={startTime.value ? "" : "err"} />
            終演<input type="time" min="10:00" max="22:00" step="300" {...endTime} required className={endTime.value ? "" : "err"} />
            退館<input type="time" min="10:00" max="22:00" step="300" {...exitTime} required className={exitTime.value ? "" : "err"} /><br />

            {openError==1 && <p><span className="err">選択された利用区分に対して開場時間の設定に誤りがあります。</span></p>}
            {openError==2 && <p><span className="err">現在の入場見込み人数では、開場時間から開演時間まで{openMinTime}分を確保する必要があります。</span></p>}
            {startError && <p><span className="err">選択された利用区分に対して開演時間の設定に誤りがあります。</span></p>}
            {endError && <p><span className="err">選択された利用区分に対して終演時間の設定に誤りがあります。</span></p>}
            {exitError && <p><span className="err">選択された利用区分に対して退館時間の設定に誤りがあります。</span></p>}
            <span>
              ※利用時間終了までに撤収を完了するよう余裕を持った時間設定をご計画ください。<br />
              ※開場時間は通常開演の大ホール40分前・小ホール30分前が目安です。
            </span>
          </td>
        </tr>
        <tr>
          <th>併設利用</th>
          <td>
            { optionRoom.map((room) => (
            <div key={room.id}>
              <select data-room={room.id} onChange={roomUpdate} value={room.room}>
                {dataApplication.venue == 10 && <>
                  {(optionRoom.filter(item => item.room == "4").length==0 || room.room == "4") &&
                    <option value="4">リハーサル室</option>
                  }
                  {(optionRoom.filter(item => item.room == "3").length==0 || room.room == "3") &&
                    <option value="3">レセプションルーム</option>
                  }
                  {(optionRoom.filter(item => item.room == "2").length==0 || room.room == "2") &&
                    <option value="2">小ホール</option>
                  }
                </>}
                {(optionRoom.filter(item => item.room == "5").length==0 || room.room == "5") &&
                  <option value="5">練習室1</option>
                }
                {(optionRoom.filter(item => item.room == "6").length==0 || room.room == "6") &&
                  <option value="6">練習室2</option>
                }
                {(optionRoom.filter(item => item.room == "7").length==0 || room.room == "7") &&
                  <option value="7">練習室3</option>
                }
                {(optionRoom.filter(item => item.room == "8").length==0 || room.room == "8") &&
                  <option value="8">練習室4</option>
                }
                {(optionRoom.filter(item => item.room == "9").length==0 || room.room == "9") &&
                  <option value="9">練習室5</option>
                }
                {(optionRoom.filter(item => item.room == "10").length==0 || room.room == "10") &&
                  <option value="10">練習室6</option>
                }
                {dataApplication.venue == 20 && <>
                  {(optionRoom.filter(item => item.room == "4").length==0 || room.room == "4") &&
                    <option value="4">リハーサル室</option>
                  }
                  {(optionRoom.filter(item => item.room == "3").length==0 || room.room == "3") &&
                    <option value="3">レセプションルーム</option>
                  }
                </>}
              </select>
              <select data-room={room.id} onChange={roomTimeUpdate} value={room.time}>
                <option value="1">午前</option>
                <option value="2">午後</option>
                <option value="4">夜間</option>
                <option value="3">午前・午後</option>
                <option value="6">午後・夜間</option>
                <option value="7">終日</option>
              </select>
              <button type="button" className="additionbtn" onClick={optionRoomDel} value={room.id}><i className="fas fa-minus"></i></button>
            </div>
            ))}
            <button type="button" className="additionbtn" onClick={optionRoomAdd}><i className="fas fa-plus"></i></button><br />
            <span>
              <a href=""><i className="fas fa-info-circle"></i>各施設の利用料</a><br />
              ※併設利用の延長についてはお問い合わせください
            </span>
          </td>
        </tr>
        </tbody>
      </table>
      </>
      }
      <div className="Form__btnbox">
        <div className="Form__btn-return">
          {dataApplication.mode === "step1"
          ? <Link to="/apply">戻る</Link>
          : <input type="button" value="戻る" onClick={prevStep} />
          }
        </div>
        <div className="Form__btn-confirm">
          {(dataApplication.mode === "step1" || timea || timeb || timec) ? 
          <input value="次へ" type="submit" id="next" disabled={hasError} onClick={nextStep} />
          :
          <input value="スキップ" type="submit" id="next" disabled={hasError} onClick={nextStep} />
          }
        </div>
      </div>
    </>
  );

}
