import { React, useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { useMyData } from './MyProvider';
import { useInput, useCheckbox } from "./hooks";

export default function ApplicationAddCompleted() {
  return (
    <>
      <h1 className="pagetopLabel">抽選申込済み</h1>

      <div className="txt">
        <p>
          この抽選には申し込み済みです。
        </p>
      </div>

      <div className="Form__btnbox">
        <div className="Form__btn-return">
          <Link to="/apply">もどる</Link>
        </div>
      </div>
    </>
  );

}
