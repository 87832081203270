import { React, useState, useEffect } from "react";
import { Outlet, useLocation, Link } from "react-router-dom";
import { useMyData } from './MyProvider';
import { useInput } from "./hooks";


export default function Apply() {
  const { data, dataApplications, setDataApplication, getApplications } = useMyData();

  useEffect(() => {
    getApplications();
    setDataApplication({
    });
  }, [data]);

  const convertDate = (date) => {
    const d = new Date(date);
    return d.getFullYear() + '年' + (d.getMonth() + 1) + '月' + d.getDate() + '日';
  }

  return (
    <>
      <h1 className="pagetopLabel">大小ホール抽選申込</h1>
      <div className="txt">
        <p>抽選申込を希望される施設（大ホール/小ホール）を選択してください。<br />※１か月に大・小ホール各１件の抽選申込が可能です。</p>
      </div>
      <ul className="l-row-center">
        {dataApplications && dataApplications.applications &&
          <>
            {dataApplications.applications.length > 0 ?
              dataApplications.applications.map((application) => (
              <li key={application.id} className="l-halfColumn Mypagebtn">
                <Link to={"/application/" + application.v4 + "/add"}>
                  {application.venue == 10 ? '大ホール ' : ''}
                  {application.venue == 20 ? '小ホール ' : ''}

                  ( {convertDate(application.tsdate)} 〜 {convertDate(application.tedate)} )
                  <br />の抽選申込
                </Link>
              </li>
            ))
            :
            <>
              <li>申し込み受付中の施設はありません。</li>
            </>
            }
          </>
        }
      </ul>


      <div className="Form__btnbox">
        <div className="Form__btn-return">
          <Link to='/' title="https://mypage.yokohama-minatomiraihall.jp/">戻る</Link>
        </div>
      </div>
    </>
  );
}
