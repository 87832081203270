import { React } from "react";
import ApplicationAddCalDate from "./ApplicationAddCalDate";
import ApplicationAddCalDay from "./ApplicationAddCalDay";

export default function ApplicationAddCalWeek(props) {

  return (
    <>
      <table className="CalendarHall">
        <thead>
          <tr>
          {props.week.map((day) => (
            <ApplicationAddCalDate key={day.key} day={day} dataApplication={props.dataApplication} />
          ))}
          </tr>
          <tr>
            <th>午前</th>
            <th>午後</th>
            <th>夜間</th>
            <th>午前</th>
            <th>午後</th>
            <th>夜間</th>
            <th>午前</th>
            <th>午後</th>
            <th>夜間</th>
            <th>午前</th>
            <th>午後</th>
            <th>夜間</th>
            <th>午前</th>
            <th>午後</th>
            <th>夜間</th>
            <th>午前</th>
            <th>午後</th>
            <th>夜間</th>
            <th>午前</th>
            <th>午後</th>
            <th>夜間</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            {props.week.map((day) => (
              <ApplicationAddCalDay key={day.key} day={day} dataApplication={props.dataApplication} />
            ))}
          </tr>
        </tbody>
      </table>
    </>
 );

}
