import { React, useState, useEffect, Fragment } from "react";
import { useMyData } from './MyProvider';
import { useInput, useCheckbox } from "./hooks";

export default function ApplicationAddForm01() {

  const { dataApplication, setDataApplication,myInfo,dataMy,getAddressFromZip } = useMyData();

  const [r_nameProps,,setRNameProps] = useInput(dataApplication.apply.r_name||""); // 利用者団体名
  const [r_d_l_nameProps,,setRDLNameProps] = useInput(dataApplication.apply.r_d_l_name||""); // 利用者代表者姓
  const [r_d_f_nameProps,,setRDFNameProps] = useInput(dataApplication.apply.r_d_f_name||""); // 利用者代表者名
  const [r_d_zipcodeProps,,r_d_zipcodeSetValue] = useInput(dataApplication.apply.r_d_zipcode||"","number"); // 利用者代表者郵便番号
  const [r_d_addressProps,,r_d_addressSetValue] = useInput(dataApplication.apply.r_d_address||""); // 利用者代表者住所
  const [r_d_tel1aProps,,r_d_tel1aSetValue] = useInput(dataApplication.apply.r_d_tel1a||"","number"); // 利用者代表者電話番号1a
  const [r_d_tel1bProps,,r_d_tel1bSetValue] = useInput(dataApplication.apply.r_d_tel1b||"","number"); // 利用者代表者電話番号1b
  const [r_d_tel1cProps,,r_d_tel1cSetValue] = useInput(dataApplication.apply.r_d_tel1c||"","number"); // 利用者代表者電話番号1c
  const [r_d_tel2aProps,,r_d_tel2aSetValue] = useInput(dataApplication.apply.r_d_tel2a||"","number"); // 利用者代表者電話番号2a
  const [r_d_tel2bProps,,r_d_tel2bSetValue] = useInput(dataApplication.apply.r_d_tel2b||"","number"); // 利用者代表者電話番号2b
  const [r_d_tel2cProps,,r_d_tel2cSetValue] = useInput(dataApplication.apply.r_d_tel2c||"","number"); // 利用者代表者電話番号2c
  const [r_d_faxaProps,,r_d_faxaSetValue] = useInput(dataApplication.apply.r_d_fax||"","number"); // 利用者代表者FAX番号a
  const [r_d_faxbProps,,r_d_faxbSetValue] = useInput(dataApplication.apply.r_d_fax||"","number"); // 利用者代表者FAX番号b
  const [r_d_faxcProps,,r_d_faxcSetValue] = useInput(dataApplication.apply.r_d_fax||"","number"); // 利用者代表者FAX番号c

  const [r_t_l_nameProps] = useInput(dataApplication.apply.r_t_l_name||""); // 利用者担当者姓
  const [r_t_f_nameProps] = useInput(dataApplication.apply.r_t_f_name||""); // 利用者担当者名
  const [r_t_l_kanaProps] = useInput(dataApplication.apply.r_t_l_kana||"",'kana'); // 利用者担当者姓カナ
  const [r_t_f_kanaProps] = useInput(dataApplication.apply.r_t_f_kana||"",'kana'); // 利用者担当者名カナ
  const [r_t_zipcodeProps] = useInput(dataApplication.apply.r_t_zipcode||"","number"); // 利用者担当者郵便番号
  const [r_t_addressProps,, r_t_addressSetValue] = useInput(dataApplication.apply.r_t_address||""); // 利用者担当者住所
  const [r_t_tel1aProps] = useInput(dataApplication.apply.r_t_tel1a||"","number"); // 利用者担当者電話番号1a
  const [r_t_tel1bProps] = useInput(dataApplication.apply.r_t_tel1b||"","number"); // 利用者担当者電話番号1b
  const [r_t_tel1cProps] = useInput(dataApplication.apply.r_t_tel1c||"","number"); // 利用者担当者電話番号1c
  const [r_t_tel2aProps] = useInput(dataApplication.apply.r_t_tel2a||"","number"); // 利用者担当者電話番号2a
  const [r_t_tel2bProps] = useInput(dataApplication.apply.r_t_tel2b||"","number"); // 利用者担当者電話番号2b
  const [r_t_tel2cProps] = useInput(dataApplication.apply.r_t_tel2c||"","number"); // 利用者担当者電話番号2c
  const [r_t_faxaProps] = useInput(dataApplication.apply.r_t_faxa||"","number"); // 利用者担当者FAX番号a
  const [r_t_faxbProps] = useInput(dataApplication.apply.r_t_faxb||"","number"); // 利用者担当者FAX番号b
  const [r_t_faxcProps] = useInput(dataApplication.apply.r_t_faxc||"","number"); // 利用者担当者FAX番号c
  const [r_t_emailProps] = useInput(dataApplication.apply.r_t_email||""); // 利用者担当者メールアドレス
  const [r_t_email1Props] = useInput(dataApplication.apply.r_t_email1||""); // 利用者担当者確認メールアドレス1
  const [r_t_email2Props] = useInput(dataApplication.apply.r_t_email2||""); // 利用者担当者確認メールアドレス2

  const [j_nameProps] = useInput(dataApplication.apply.j_name||""); // 事務代行団体名
  const [j_kanaProps] = useInput(dataApplication.apply.j_kana||"",'kana'); // 事務代行団体名カナ
  const [j_d_l_nameProps] = useInput(dataApplication.apply.j_d_l_name||""); // 事務代行代表者姓
  const [j_d_f_nameProps] = useInput(dataApplication.apply.j_d_f_name||""); // 事務代行代表者名
  const [j_d_l_kanaProps] = useInput(dataApplication.apply.j_d_l_kana||"",'kana'); // 事務代行代表者姓カナ
  const [j_d_f_kanaProps] = useInput(dataApplication.apply.j_d_f_kana||"",'kana'); // 事務代行代表者名カナ
  const [j_t_l_nameProps] = useInput(dataApplication.apply.j_t_l_name||""); // 事務代行担当者姓
  const [j_t_f_nameProps] = useInput(dataApplication.apply.j_t_f_name||""); // 事務代行担当者名
  const [j_t_l_kanaProps] = useInput(dataApplication.apply.j_t_l_kana||"",'kana'); // 事務代行担当者姓カナ
  const [j_t_f_kanaProps] = useInput(dataApplication.apply.j_t_f_kana||"",'kana'); // 事務代行担当者名カナ

  const [j_emailProps] = useInput(dataApplication.apply.j_email||""); // 事務代行メールアドレス
  const [j_email1Props] = useInput(dataApplication.apply.j_email1||""); // 事務代行確認メールアドレス1
  const [j_email2Props] = useInput(dataApplication.apply.j_email2||""); // 事務代行確認メールアドレス2
  const [j_zipcodeProps] = useInput(dataApplication.apply.j_zipcode||"","number"); // 事務代行郵便番号
  const [j_addressProps,, j_addressSetValue] = useInput(dataApplication.apply.j_address||""); // 事務代行住所
  const [j_tel1aProps] = useInput(dataApplication.apply.j_tel1a||"","number"); // 事務代行電話番号1a
  const [j_tel1bProps] = useInput(dataApplication.apply.j_tel1b||"","number"); // 事務代行電話番号1b
  const [j_tel1cProps] = useInput(dataApplication.apply.j_tel1c||"","number"); // 事務代行電話番号1c
  const [j_tel2aProps] = useInput(dataApplication.apply.j_tel2a||"","number"); // 事務代行電話番号2a
  const [j_tel2bProps] = useInput(dataApplication.apply.j_tel2b||"","number"); // 事務代行電話番号2b
  const [j_tel2cProps] = useInput(dataApplication.apply.j_tel2c||"","number"); // 事務代行電話番号2c
  const [j_faxaProps] = useInput(dataApplication.apply.j_faxa||"","number"); // 事務代行FAX番号
  const [j_faxbProps] = useInput(dataApplication.apply.j_faxb||"","number"); // 事務代行FAX番号
  const [j_faxcProps] = useInput(dataApplication.apply.j_faxc||"","number"); // 事務代行FAX番号

  const [check1Props] = useCheckbox(dataApplication.apply.check1 ? [...dataApplication.apply.check1] : []); // チェック1
  const [check2Props] = useCheckbox(dataApplication.apply.check2 ? [...dataApplication.apply.check2] : []); // チェック2
  const [check3Props] = useCheckbox(dataApplication.apply.check3 ? [...dataApplication.apply.check3] : []); // チェック3

  const [hasError, setHasError] = useState(true); // エラーチェック

  const [r_t_mailCheck, set_r_t_mailCheck] = useState(true); // 利用者担当者メールアドレス エラーチェック
  const [j_mailCheck, set_j_mailCheck] = useState(true); // 事務代行メールアドレス エラーチェック

  const [init, setInit] = useState(true); // 最初の一回を検出するために
  const [zipInit, setZipInit] = useState(false); // zipcode書き換え防止


  const wday = ['日','月','火','水','木','金','土'];

  const getDateStr = (req) => {
    let dateStr = "";
    if(req.length > 0) {
      const reqDateStr = req[0].substr(0,req[0].length -1);
      const reqDate = new Date(reqDateStr);
      let term = new Array();
      req.sort().forEach((t)=>{
        if(t.substr(-1)=="a") term.push('午前');
        if(t.substr(-1)=="b") term.push('午後');
        if(t.substr(-1)=="c") term.push('夜間');
      });

      dateStr = reqDate.getFullYear() + "年"+(reqDate.getMonth()+1) + "月"+ reqDate.getDate()+"日（"+ wday[reqDate.getDay()] +"）" + term.join("・");
    }
    return dateStr;
  }

  const makeRsvConfirmStr = (step) => {
    let ele = [];
    let retStr="";
    if(step.encho==1 && (step.enchoBefore > 0 || step.enchoAfter > 0) )  {
      retStr+=" 延長";
      if(step.enchoBefore > 0) {
        if(step.enchoBefore==60) {
          retStr+=" 前1時間";
        } else {
          retStr+=" 前" + step.enchoBefore + "分";
        }
      }
      if(step.enchoAfter > 0) {
        if(step.enchoAfter==60) {
          retStr+=" 後1時間";
        } else {
          retStr+=" 後" + step.enchoAfter + "分";
        }
      }
      ele.push(<>{retStr}</>);
      ele.push(<br />);
    }
    step.optionRoom.forEach((e,i) => {
      let room="";
      let kubun="";
      if(e.room==2) room="小ホール";
      if(e.room==3) room="レセプションルーム";
      if(e.room==4) room="リハーサル室";
      if(e.room==5) room="練習室1";
      if(e.room==6) room="練習室2";
      if(e.room==7) room="練習室3";
      if(e.room==8) room="練習室4";
      if(e.room==9) room="練習室5";
      if(e.room==10) room="練習室6";

      if(e.time==1) kubun="午前";
      if(e.time==2) kubun="午後";
      if(e.time==3) kubun="午前・午後";
      if(e.time==4) kubun="夜間";
      if(e.time==6) kubun="午後・夜間";
      if(e.time==7) kubun="終日";

      ele.push(<Fragment key={i}>併用施設 {room} {kubun}<br /></Fragment>);

    });

    return <>{ele}</>;
  }

  // 利用者情報取得
  useEffect(() => {
    if(dataMy.r_name){
      setRNameProps(dataMy.r_name);
      setRDLNameProps(dataMy.r_d_l_name);
      setRDFNameProps(dataMy.r_d_f_name);
      if(r_d_zipcodeProps.value === ''){
        setZipInit(true);
        r_d_zipcodeSetValue(dataMy.r_zipcode);
        r_d_addressSetValue(dataMy.r_address);

        const r_tel1 = dataMy.r_tel1.split('-');
        const r_tel2 = dataMy.r_tel2.split('-');
        const r_fax = dataMy.r_fax.split('-');
        if(r_tel1.length === 3) {
          r_d_tel1aSetValue(r_tel1[0]);
          r_d_tel1bSetValue(r_tel1[1]);
          r_d_tel1cSetValue(r_tel1[2]);
        }
        if(r_tel2.length === 3) {
          r_d_tel2aSetValue(r_tel2[0]);
          r_d_tel2bSetValue(r_tel2[1]);
          r_d_tel2cSetValue(r_tel2[2]);
        }
        if(r_fax.length === 3) {
          r_d_faxaSetValue(r_fax[0]);
          r_d_faxbSetValue(r_fax[1]);
          r_d_faxcSetValue(r_fax[2]); 
        }
      }
    }
  },[dataMy]);

  useEffect(() => {
    myInfo();
    setInit(false);
  },[]);

  // 郵便番号から住所を取得
  useEffect(() => {
    if(! init){
      if(! zipInit){
        getAddressFromZip( r_d_zipcodeProps.value, r_d_addressSetValue );
      }
    }
    if(r_d_zipcodeProps.value !== ''){
      setZipInit(false);
    }
  },[r_d_zipcodeProps.value]);

  useEffect(() => {
    if(! init){
      getAddressFromZip( r_t_zipcodeProps.value, r_t_addressSetValue );
    }
  },[r_t_zipcodeProps.value]);

  useEffect(() => {
    if(! init){
      getAddressFromZip( j_zipcodeProps.value, j_addressSetValue );
    }
  },[j_zipcodeProps.value]);


  // エラーチェック
  useEffect(() => {
    let err = false;

    // 必須チェック
    if(r_d_zipcodeProps.value==="") err=true;
    if(r_d_addressProps.value==="") err=true;
    if(r_d_tel1aProps.value==="") err=true;
    if(r_d_tel1bProps.value==="") err=true;
    if(r_d_tel1cProps.value==="") err=true;

    if(!check1Props.val.includes("no")){
      if(r_t_l_nameProps.value==="") err=true;
      if(r_t_f_nameProps.value==="") err=true;
      if(r_t_l_kanaProps.value==="") err=true;
      if(r_t_f_kanaProps.value==="") err=true;

      if(!check2Props.val.includes("no")){
        if(r_t_zipcodeProps.value==="") err=true;
        if(r_t_addressProps.value==="") err=true;
      }
      if(r_t_tel1aProps.value==="") err=true;
      if(r_t_tel1bProps.value==="") err=true;
      if(r_t_tel1cProps.value==="") err=true;
    }

    if(check3Props.val.includes("yes") && ( check1Props.val.includes("no") || check2Props.val.includes("no") ) ){
      if(j_nameProps.value==="") err=true;
      if(j_d_l_nameProps.value==="") err=true;
      if(j_d_f_nameProps.value==="") err=true;
      if(j_d_l_kanaProps.value==="") err=true;
      if(j_d_f_kanaProps.value==="") err=true;
      if(j_zipcodeProps.value==="") err=true;
      if(j_addressProps.value==="") err=true;
      if(j_tel1aProps.value==="") err=true;
      if(j_tel1bProps.value==="") err=true;
      if(j_tel1cProps.value==="") err=true;
    }

    // メールアドレスチェック
    if ( r_t_emailProps.value !== ''){
      if ( r_t_emailProps.value === r_t_email1Props.value + '@' + r_t_email2Props.value ) {
        set_r_t_mailCheck(true);
      } else {
        set_r_t_mailCheck(false);
        err = true;
      }
    } else {
      set_r_t_mailCheck(true);
    }

    if ( j_emailProps.value !== ''){
      if ( j_emailProps.value === j_email1Props.value + '@' + j_email2Props.value ) {
        set_j_mailCheck(true);
      } else {
        set_j_mailCheck(false);
        err = true;
      }
    } else {
      set_j_mailCheck(true);
    }

    setHasError(err);
  });

  const makeData = () => {
    let apply = {};
    apply.r_name = dataMy.r_name;
    apply.r_d_l_name = dataMy.r_d_l_name;
    apply.r_d_f_name = dataMy.r_d_f_name;
    apply.r_d_zipcode = r_d_zipcodeProps.value;
    apply.r_d_address = r_d_addressProps.value;
    apply.r_d_tel1a = r_d_tel1aProps.value;
    apply.r_d_tel1b = r_d_tel1bProps.value;
    apply.r_d_tel1c = r_d_tel1cProps.value;
    apply.r_d_tel2a = r_d_tel2aProps.value;
    apply.r_d_tel2b = r_d_tel2bProps.value;
    apply.r_d_tel2c = r_d_tel2cProps.value;
    apply.r_d_faxa = r_d_faxaProps.value;
    apply.r_d_faxb = r_d_faxbProps.value;
    apply.r_d_faxc = r_d_faxcProps.value;

    apply.r_t_l_name = r_t_l_nameProps.value;
    apply.r_t_f_name = r_t_f_nameProps.value;
    apply.r_t_l_kana = r_t_l_kanaProps.value;
    apply.r_t_f_kana = r_t_f_kanaProps.value;
    apply.r_t_zipcode = r_t_zipcodeProps.value;
    apply.r_t_address = r_t_addressProps.value;
    apply.r_t_tel1a = r_t_tel1aProps.value;
    apply.r_t_tel1b = r_t_tel1bProps.value;
    apply.r_t_tel1c = r_t_tel1cProps.value;
    apply.r_t_tel2a = r_t_tel2aProps.value;
    apply.r_t_tel2b = r_t_tel2bProps.value;
    apply.r_t_tel2c = r_t_tel2cProps.value;
    apply.r_t_faxa = r_t_faxaProps.value;
    apply.r_t_faxb = r_t_faxbProps.value;
    apply.r_t_faxc = r_t_faxcProps.value;
    apply.r_t_email = r_t_emailProps.value;
    apply.r_t_email1 = r_t_email1Props.value;
    apply.r_t_email2 = r_t_email2Props.value;

    apply.j_name = j_nameProps.value;
    apply.j_kana = j_kanaProps.value;
    apply.j_d_l_name = j_d_l_nameProps.value;
    apply.j_d_f_name = j_d_f_nameProps.value;
    apply.j_d_l_kana = j_d_l_kanaProps.value;
    apply.j_d_f_kana = j_d_f_kanaProps.value;
    apply.j_t_l_name = j_t_l_nameProps.value;
    apply.j_t_f_name = j_t_f_nameProps.value;
    apply.j_t_l_kana = j_t_l_kanaProps.value;
    apply.j_t_f_kana = j_t_f_kanaProps.value;

    apply.j_email = j_emailProps.value;
    apply.j_email1 = j_email1Props.value;
    apply.j_email2 = j_email2Props.value;
    apply.j_zipcode = j_zipcodeProps.value;
    apply.j_address = j_addressProps.value;
    apply.j_tel1a = j_tel1aProps.value;
    apply.j_tel1b = j_tel1bProps.value;
    apply.j_tel1c = j_tel1cProps.value;
    apply.j_tel2a = j_tel2aProps.value;
    apply.j_tel2b = j_tel2bProps.value;
    apply.j_tel2c = j_tel2cProps.value;
    apply.j_faxa = j_faxaProps.value;
    apply.j_faxb = j_faxbProps.value;
    apply.j_faxc = j_faxcProps.value;

    apply.check1 = check1Props.val;
    apply.check2 = check2Props.val;
    apply.check3 = check3Props.val;

    return apply;
  }

  const nextStep = () => {
    window.scrollTo({
      top: 0,
    });
    const apply = makeData();

    setDataApplication({
      ...dataApplication,
      "mode": "step5",
      apply
    })
  };

  const prevStep = () => {
    window.scrollTo({
      top: 0,
    });
    const apply = makeData();

    let nextMode = 'step3';
    if ( dataApplication.req2.length == 0 ) nextMode = 'step2';


    setDataApplication({
      ...dataApplication,
      "mode": nextMode,
      apply
    })
  };
  return (
    <>
      <h1 className="pagetopLabel">利用許可申請情報</h1>

      <ul className="Progressbar">
        <li className="complete"><span>第一希望選択</span></li>
        <li className="complete"><span>第二希望選択</span></li>
        <li className="complete"><span>第三希望選択</span></li>
        <li className="active"><span>利用許可<br />申請情報の入力</span></li>
        <li><span>公演計画<br />申請情報の入力</span></li>
        <li><span>確認</span></li>
        <li><span>抽選申込完了</span></li>
      </ul>

      <h6>【利用日】</h6>
      <table>
        <tbody>
          <tr>
            <th>第一希望</th>
            {dataApplication.req1.length > 0 ?
            <td>{dataApplication.venue==10 ? '大' : '小'}ホール　{getDateStr(dataApplication.req1)}
              {makeRsvConfirmStr(dataApplication.step1)}
            </td>
            :
            <td>なし</td>
            }
          </tr>
          <tr>
            <th>第二希望</th>
            {dataApplication.req2.length > 0 ?
            <td>{dataApplication.venue==10 ? '大' : '小'}ホール　{getDateStr(dataApplication.req2)}
              {makeRsvConfirmStr(dataApplication.step2)}
            </td>
            :
            <td>なし</td>
            }
          </tr>
          <tr>
            <th>第三希望</th>
            {dataApplication.req3.length > 0 ?
            <td>{dataApplication.venue==10 ? '大' : '小'}ホール　{getDateStr(dataApplication.req3)}
              {makeRsvConfirmStr(dataApplication.step3)}
            </td>
            :
            <td>なし</td>
            }
          </tr>
        </tbody>
      </table>

      <h6>【申請者情報】</h6>
      <p>申請者の情報を入力・確認してください。</p>
      <table className="Form">
        <tbody><tr>
          <th>団体名</th>
          <td><input  value={dataMy.r_name} size="40" type="text" required="" disabled /></td>
        </tr>
        <tr>
          <th>代表者名</th>
          <td>
            姓<input name="name" size="14" type="text" value={dataMy.r_d_l_name} disabled />
            名<input name="name" size="14" type="text" value={dataMy.r_d_f_name} disabled />
          </td>
        </tr>
        <tr>
          <th>代表者住所 <span>※必須</span></th>
          <td>
            郵便番号<input type="text" {...r_d_zipcodeProps} autoComplete="postal-code" className="w_20" required /><span>（半角数字、ハイフンなし）</span><br />
            <input type="text" {...r_d_addressProps} required size="50" className="w_60" />
          </td>
        </tr>
        <tr>
          <th>電話番号1 <span>※必須</span></th>
          <td><input {...r_d_tel1aProps} size="10" className="w_10" type="text" maxLength="4" required />ー<input {...r_d_tel1bProps} size="10" className="w_10" type="text" maxLength="4" required />ー<input {...r_d_tel1cProps} size="10" className="w_10" type="text" maxLength="4" required /><span>（半角数字）</span></td>
        </tr>
        <tr>
          <th>電話番号2</th>
          <td><input {...r_d_tel2aProps} size="10" className="w_10" type="text" maxLength="4" />ー<input {...r_d_tel2bProps} size="10" className="w_10" type="text" maxLength="4" />ー<input {...r_d_tel2cProps} size="10" className="w_10" type="text" maxLength="4" /><span>（半角数字）</span></td>
        </tr>
        <tr>
          <th>FAX</th>
          <td><input {...r_d_faxaProps} size="10" className="w_10" type="text" maxLength="4" />ー<input {...r_d_faxbProps} size="10" className="w_10" type="text" maxLength="4" />ー<input {...r_d_faxcProps} size="10" className="w_10" type="text" maxLength="4" /><span>（半角数字）</span></td>
        </tr>
      </tbody>
      </table>
      <p>入力内容を変更してもマイページ登録済の情報（住所等）は変更されません。<br />マイページ登録済情報の変更は、【マイページ】→【利用者情報 確認・変更】から行ってください。</p><br />

      <h6>【担当者情報】</h6>
      <div className="txt">
        <p>代表者とは別に担当者を置く場合のみ入力してください。<br />入力された場合、ホールから申請者への書類送付等は担当者宛に行います。</p><br />
        <p>
          <label><input type="checkbox" checked={check1Props.val.includes("no") ? "checked" : ""} value="no" {...check1Props} /> 代表者とは別の担当者はいません。</label><br />
          {!check1Props.val.includes("no") && <label><input type="checkbox" checked={check2Props.val.includes("no") ? "checked" : ""} value="no" {...check2Props} /> 担当者は代表者と住所（書類送付先）は同じです。</label> }
        </p>
      </div>
      {!check1Props.val.includes("no") &&
      <table className="Form" summary="#">
        <tbody>
        <tr>
          <th>担当者名<span>※必須</span></th>
          <td>
            姓<input size="20" type="text" className="w_40" {...r_t_l_nameProps} />
            名<input size="20" type="text" className="w_40" {...r_t_f_nameProps} />
          </td>
        </tr>
        <tr>
          <th>担当者名フリガナ <span>※必須</span></th>
          <td>
            姓<input size="20" type="text" className="w_40" {...r_t_l_kanaProps} />
            名<input size="20" type="text" className="w_40" {...r_t_f_kanaProps} />
          </td>
        </tr>
        {!check2Props.val.includes("no") &&
        <tr>
          <th>担当者ご住所 <span>※必須</span></th>
          <td>
            郵便番号<input type="text" {...r_t_zipcodeProps} autoComplete="postal-code" className="w_20" required /><span>（半角数字、ハイフンなし）</span><br />
            <input type="text" {...r_t_addressProps} required size="50" className="w_60" />
          </td>
        </tr>
        }
        <tr>
          <th>電話番号1 <span>※必須</span></th>
          <td><input {...r_t_tel1aProps} size="10" className="w_10" type="text" maxLength="4" required />ー<input {...r_t_tel1bProps} size="10" className="w_10" type="text" maxLength="4" required />ー<input {...r_t_tel1cProps} size="10" className="w_10" type="text" maxLength="4" required /><span>（半角数字）</span></td>
        </tr>
        <tr>
          <th>電話番号2</th>
          <td><input {...r_t_tel2aProps} size="10" className="w_10" type="text" maxLength="4" />ー<input {...r_t_tel2bProps} size="10" className="w_10" type="text" maxLength="4" />ー<input {...r_t_tel2cProps} size="10" className="w_10" type="text" maxLength="4" /><span>（半角数字）</span></td>
        </tr>
        <tr>
          <th>FAX</th>
          <td><input {...r_t_faxaProps} size="10" className="w_10" type="text" maxLength="4" />ー<input {...r_t_faxbProps} size="10" className="w_10" type="text" maxLength="4" />ー<input {...r_t_faxcProps} size="10" className="w_10" type="text" maxLength="4" /><span>（半角数字）</span></td>
        </tr>
        <tr>
          <th>メールアドレス</th>
          <td><input type="email" {...r_t_emailProps} size="40" width="100%" autoComplete="email" pattern="^[0-9a-zA-Z_\.\+\-]+@[0-9a-zA-Z_\.\-]+\.[0-9a-zA-Z_\.\-]+$" /><span>（半角英数字）</span></td>
        </tr>
        <tr>
          <th>メールアドレス（確認）</th>
          <td>
            <input type="text" {...r_t_email1Props} size="30" width="100%" className="w_30" required pattern="^[0-9a-zA-Z_\.\+\-]+$" />＠<input type="text" {...r_t_email2Props} size="20" width="100%" className="w_20" required pattern="^[0-9a-zA-Z_\.\-]+\.[0-9a-zA-Z_\.\-]+$"/ ><span>（半角英数字）</span><br />
            { !r_t_mailCheck  && <span className="notice">メールアドレスが一致しません</span>}
          </td>
        </tr>
      </tbody>
      </table>
      }

      <h6>【事務担当者情報】</h6>
      <div className="txt">
        <p>
          <span>申請者と別団体で、事務担当者（許可書受け取り・料金支払、諸連絡等を行う方）を置く場合のみ入力してください。<br />入力された場合、ホールからの諸連絡は、申請者(代表者/担当者)宛ではなく事務担当者宛に行います。</span>
        </p><br />
        <p><label><input type="checkbox" disabled={!check1Props.val.includes("no") && !check2Props.val.includes("no") ? 'disabled' : ''} checked={check3Props.val.includes("yes") && ( check1Props.val.includes("no") || check2Props.val.includes("no") ) ? "checked" : ""} value="yes" {...check3Props} /> 申請者とは別団体の事務担当者がいます。</label></p>
      </div>
      {check3Props.val.includes("yes") && ( check1Props.val.includes("no") || check2Props.val.includes("no") ) &&
      <table className="Form" summary="#" id="Area">
        <tbody><tr>
          <th>団体名 <span>※必須</span></th>
          <td><input {...j_nameProps} size="40" type="text" /></td>
        </tr>
        <tr>
          <th>代表者名<span>※必須</span></th>
          <td>
            姓<input size="20" type="text" className="w_40" {...j_d_l_nameProps} />
            名<input size="20" type="text" className="w_40" {...j_d_f_nameProps} />
          </td>
        </tr>
        <tr>
          <th>代表者名フリガナ <span>※必須</span></th>
          <td>
            姓<input size="20" type="text" className="w_40" {...j_d_l_kanaProps} />
            名<input size="20" type="text" className="w_40" {...j_d_f_kanaProps} />
          </td>
        </tr>
        <tr>
          <th>担当者名</th>
          <td>
            姓<input size="20" type="text" className="w_40" {...j_t_l_nameProps} />
            名<input size="20" type="text" className="w_40" {...j_t_f_nameProps} />
          </td>
        </tr>
        <tr>
          <th>担当者名フリガナ</th>
          <td>
            姓<input size="20" type="text" className="w_40" {...j_t_l_kanaProps} />
            名<input size="20" type="text" className="w_40" {...j_t_f_kanaProps} />
          </td>
        </tr>
        <tr>
          <th>ご住所 <span>※必須</span></th>
          <td>
            郵便番号<input type="text" {...j_zipcodeProps} autoComplete="postal-code" className="w_20" required /><span>（半角数字、ハイフンなし）</span><br />
            <input type="text" {...j_addressProps} required size="50" className="w_60" />
          </td>
        </tr>
        <tr>
          <th>電話番号1 <span>※必須</span></th>
          <td><input {...j_tel1aProps} size="10" className="w_10" type="text" maxLength="4" required />ー<input {...j_tel1bProps} size="10" className="w_10" type="text" maxLength="4" required />ー<input {...j_tel1cProps} size="10" className="w_10" type="text" maxLength="4" required /><span>（半角数字）</span></td>
        </tr>
        <tr>
          <th>電話番号2</th>
          <td><input {...j_tel2aProps} size="10" className="w_10" type="text" maxLength="4" required />ー<input {...j_tel2bProps} size="10" className="w_10" type="text" maxLength="4" required />ー<input {...j_tel2cProps} size="10" className="w_10" type="text" maxLength="4" required /><span>（半角数字）</span></td>
        </tr>
        <tr>
          <th>FAX</th>
          <td><input {...j_faxaProps} size="10" className="w_10" type="text" maxLength="4" required />ー<input {...j_faxbProps} size="10" className="w_10" type="text" maxLength="4" required />ー<input {...j_faxcProps} size="10" className="w_10" type="text" maxLength="4" required /><span>（半角数字）</span></td>
        </tr>
        <tr>
          <th>メールアドレス</th>
          <td><input type="email" {...j_emailProps} size="40" width="100%" autoComplete="email" pattern="^[0-9a-zA-Z_\.\+\-]+@[0-9a-zA-Z_\.\-]+\.[0-9a-zA-Z_\.\-]+$" /><span>（半角英数字）</span></td>
        </tr>
        <tr>
          <th>メールアドレス（確認）</th>
          <td>
            <input type="text" {...j_email1Props} size="30" width="100%" className="w_30" required pattern="^[0-9a-zA-Z_\.\+\-]+$" />＠<input type="text" {...j_email2Props} size="20" width="100%" className="w_20" required pattern="^[0-9a-zA-Z_\.\-]+\.[0-9a-zA-Z_\.\-]+$"/ ><span>（半角英数字）</span><br />
            { !j_mailCheck  && <span className="notice">メールアドレスが一致しません</span>}
          </td>
        </tr>
      </tbody>
      </table>
      }

      <div className="Form__btnbox">
        <div className="Form__btn-return">
          <input type="button" value="戻る" onClick={prevStep} />
        </div>
        <div className="Form__btn-confirm">
          <input value="次へ" type="submit" id="next" disabled={hasError} onClick={nextStep} />
        </div>
      </div>
    </>
  );

}
