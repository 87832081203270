import React from "react";
import { StyleSheet, Text, View } from "@react-pdf/renderer";
import { Grid, Col } from "./";

export default props => {
  return (
    <>
        <Grid {...props} borderTop borderLeft>
          <Col borderRight
            style={{
              width: '35mm',
              justifyContent: "start"
            }}
          >
          {props.keys.map((key, index) => (
            <Text key={index}>{key}</Text>
          ))}
          </Col>
          <Col
            borderRight
            style={{
              width: '165mm',
              justifyContent: "start"
            }}
          >
            {props.values.map((val, index) => (
              <Text key={index}>{val}</Text>
            ))}
          </Col>
        </Grid>
  </>
  );
};