import { React, useState, useEffect } from "react";
import ApplicationAddCalWeek from "./ApplicationAddCalWeek";

export default function ApplicationAddCal(props) {

  let theDay = new Date(props.month);
  let d = (theDay.getDay() + 6) % 7;
  let m = theDay.getMonth();

  let count=1;
  let date = new Array();
  for (let i = 0; i < d ; i++ ) {
    date.push({key: count});
    count++;
  }
  while ( m == theDay.getMonth() ) {
    date.push({
      date: new Date(theDay) ,
      key: count ,
    });
    theDay.setDate(theDay.getDate() + 1);
    count++;
  }
  d = 7 - (theDay.getDay() + 6) % 7;
  if(d < 7) {
    for (let i = 0; i < d ; i++ ) {
      date.push({key: count});
      count++;
    }
  }
  
  const sliceByNumber = (array, number) => {
    const length = Math.ceil(array.length / number)
    return new Array(length).fill().map((_, i) =>
      array.slice(i * number, (i + 1) * number)
    )
  }

  const weeks = sliceByNumber(date,7);


  return (
    <>
      <table className="CalendarHall forsp">
        <thead>
          <tr>
            <th colSpan={3}></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>午前<br /><span>（9:00〜12:00）</span></td>
            <td>午後<br /><span>（13:00〜16:30）</span></td>
            <td>夜間<br /><span>（17:30〜22:00）</span></td>
          </tr>
        </tbody>
      </table>
      {weeks.map((week) => (
          <ApplicationAddCalWeek key={week[0].key} week={week} dataApplication={props.dataApplication} />
      ))}
      </>
 );

}
