
import { React, useState, useEffect } from "react";
import ReactDOM from 'react-dom';
import { useNavigate, useParams } from "react-router-dom";
import { useMyData } from './MyProvider';
import { useInput, useCheckbox } from "./hooks";
import ApplyDetailPdf from "./ApplyDetailPdf";
import ApplyDetailPdf2 from "./ApplyDetailPdf2";
import { usePDF, Document, Page, Text, View,  } from '@react-pdf/renderer';

const MyDoc = (
  <Document>
    <Page>
      <View>
        <Text>Section #1</Text>
        <Text>日本語</Text>
      </View>
    </Page>
  </Document>
);

export default function ApplyDetail() {
  let {id} = useParams();
  const navigate = useNavigate();

  const { dataApplication, getApplicationsDetail } = useMyData();

  const [loaded, setLoaded] = useState(false);

  const [instance, updateInstance] = usePDF({ document: <ApplyDetailPdf data={dataApplication} /> });
  const [instance2, updateInstance2] = usePDF({ document: <ApplyDetailPdf2 data={dataApplication} /> });

  useEffect(() => {
    console.log("OK");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    getApplicationsDetail(id, setLoaded);
  }, []);

  useEffect(() => {
    updateInstance();
    updateInstance2();
  }, [dataApplication])


  const getDataStr = (req) => {
    let dataStr = "";
    if(req.date) {
      dataStr += `${dataApplication.venue==10 ? '大' : '小'}ホール　`;
      dataStr += `${dataApplication.venue==10 && dataApplication.req1.seat==1 ? '（A型：全階利用）　' : ''}`;
      dataStr += `${dataApplication.venue==10 && dataApplication.req1.seat==2 ? '（B型：1階のみ）　' : ''}`;

      let term = [];
      if(req.time1 > 0) term.push('午前');
      if(req.time2 > 0) term.push('午後');
      if(req.time3 > 0) term.push('夜間');

      dataStr += req.date_t + "　" + term.join("・");
    }
    return dataStr;
  }
  const getOptionStr = (req) => {
    let retStr = "";
    if(req.date) {
      let room = [];
      if(req.option1 > 0) room.push('大ホール');
      if(req.option2 > 0) room.push('小ホール');
      if(req.option3 > 0) room.push('レセプションルーム');
      if(req.option4 > 0) room.push('リハーサル室');
      if(req.proom1 > 0) room.push('練習室1');
      if(req.proom2 > 0) room.push('練習室2');
      if(req.proom3 > 0) room.push('練習室3');
      if(req.proom4 > 0) room.push('練習室4');
      if(req.proom5 > 0) room.push('練習室5');
      if(req.proom6 > 0) room.push('練習室6');

      if(room.length > 0) {
        retStr = '併設利用 ： ' + room.join("・");
      }
    }
    return retStr;
  }

  const getOptionGuests = (req) => {
    let retStr = "";
    if(req.guests) {
      retStr = '集客見込 ： ' + req.guests + "名程度";
    }
    return retStr;
  }

  const printpage = () => {
    window.print();
  }

  return (
    <>
    {loaded ?
    <>
      <h1 className="pagetopLabel">{dataApplication.venue==10 ? '大' : '小'}ホール 抽選申し込み情報 ({dataApplication.no})</h1>

      <h6>【利用日】</h6>
      <table>
        <tbody>
          <tr>
            <th>第一希望</th>
            {dataApplication.req1.date ?
            <td>
              {getDataStr(dataApplication.req1)}<br />
              {getOptionStr(dataApplication.req1)}<br />
              {getOptionGuests(dataApplication.req1)}
            </td>
            :
            <td>なし</td>
            }
          </tr>
          <tr>
            <th>第二希望</th>
            {dataApplication.req2.date ?
            <td>
              {getDataStr(dataApplication.req2)}<br />
              {getOptionStr(dataApplication.req2)}<br />
              {getOptionGuests(dataApplication.req2)}
            </td>
          :
            <td>なし</td>
            }
          </tr>
          <tr>
            <th>第三希望</th>
            {dataApplication.req3.date ?
            <td>
              {getDataStr(dataApplication.req3)}<br />
              {getOptionStr(dataApplication.req3)}<br />
              {getOptionGuests(dataApplication.req3)}
            </td>
          :
            <td>なし</td>
            }
          </tr>
        </tbody>
      </table>

      <h6>【申請者情報】</h6>
      <table className="Form">
        <tbody><tr>
          <th>団体名</th>
          <td>{dataApplication.apply.r_name}</td>
        </tr>
        <tr>
          <th>代表者名</th>
          <td>
            {dataApplication.apply.r_d_l_name}
            {dataApplication.apply.r_d_f_name}
          </td>
        </tr>
        <tr>
          <th>代表者住所 <span>※必須</span></th>
          <td>
            郵便番号 {dataApplication.apply.r_d_zipcode}<br />
            {dataApplication.apply.r_d_address}
          </td>
        </tr>
        <tr>
          <th>電話番号1 <span>※必須</span></th>
          <td>{dataApplication.apply.r_d_tel1}</td>
        </tr>
        <tr>
          <th>電話番号2</th>
          <td>
            {dataApplication.apply.r_d_tel2 &&
            <>
            {dataApplication.apply.r_d_tel2}
            </>
            }
          </td>
        </tr>
        <tr>
          <th>FAX</th>
          <td>
            {dataApplication.apply.r_d_fax &&
            <>
            {dataApplication.apply.r_d_fax}
            </>
            }
          </td>
        </tr>
      </tbody>
      </table>

      {!dataApplication.apply.check1.includes("no") &&
      <>
      <h6>【担当者情報】</h6>
      <div className="txt">
        <p>
          {dataApplication.apply.check1.includes("no") ? 
            <><label>代表者とは別の担当者はいません。</label><br /></> 
          :
            <> {dataApplication.apply.check2.includes("no") && <><label>担当者は代表者と住所（書類送付先）は同じです。</label><br /></>}</>
          }
        </p>
      </div>
      </>
      }
      {!dataApplication.apply.check1.includes("no") &&
      <table className="Form" summary="#">
        <tbody>
        <tr>
          <th>担当者名<span>※必須</span></th>
          <td>
            {dataApplication.apply.r_t_l_name}
            {dataApplication.apply.r_t_f_name}
          </td>
        </tr>
        <tr>
          <th>担当者名フリガナ <span>※必須</span></th>
          <td>
            {dataApplication.apply.r_t_l_kana}
            {dataApplication.apply.r_t_f_kana}
          </td>
        </tr>
        {!dataApplication.apply.check2.includes("no") &&
        <tr>
          <th>担当者ご住所 <span>※必須</span></th>
          <td>
            郵便番号 {dataApplication.apply.r_t_zipcode}<br />
            {dataApplication.apply.r_t_address}
          </td>
        </tr>
        }
        <tr>
          <th>電話番号1 <span>※必須</span></th>
          <td>{dataApplication.apply.r_t_tel1}</td>
        </tr>
        <tr>
          <th>電話番号2</th>
          <td>
          {dataApplication.apply.r_t_tel2 &&
            <>
            {dataApplication.apply.r_t_tel2}
            </>
            }
          </td>
        </tr>
        <tr>
          <th>FAX</th>
          <td>
          {dataApplication.apply.r_t_fax &&
            <>
            {dataApplication.apply.r_t_fax}
            </>
            }
          </td>
        </tr>
        <tr>
          <th>メールアドレス</th>
          <td>{dataApplication.apply.r_t_email}</td>
        </tr>
      </tbody>
      </table>
      }

      {dataApplication.apply.check3.includes("yes") && 
      <>
        <h6>【事務担当者情報】</h6>
        <div className="txt">
          <p><label>申請者とは別団体の事務担当者がいます。</label></p>
        </div>
      </>
      }
      {dataApplication.apply.check3.includes("yes") &&
      <table className="Form" summary="#" id="Area">
        <tbody><tr>
          <th>団体名 <span>※必須</span></th>
          <td>{dataApplication.apply.j_name}</td>
        </tr>
        <tr>
          <th>代表者名<span>※必須</span></th>
          <td>
            {dataApplication.apply.j_d_l_name}
            {dataApplication.apply.j_d_f_name}
          </td>
        </tr>
        <tr>
          <th>代表者名フリガナ <span>※必須</span></th>
          <td>
            {dataApplication.apply.j_d_l_kana}
            {dataApplication.apply.j_d_f_kana}
          </td>
        </tr>
        <tr>
          <th>担当者名</th>
          <td>
            {dataApplication.apply.j_t_l_name}
            {dataApplication.apply.j_t_f_name}
          </td>
        </tr>
        <tr>
          <th>担当者名フリガナ</th>
          <td>
            {dataApplication.apply.j_t_l_kana}
            {dataApplication.apply.j_t_f_kana}
          </td>
        </tr>
        <tr>
          <th>ご住所 <span>※必須</span></th>
          <td>
            郵便番号 {dataApplication.apply.j_zipcode}<br />
            {dataApplication.apply.j_address}
          </td>
        </tr>
        <tr>
          <th>電話番号1 <span>※必須</span></th>
          <td>{dataApplication.apply.j_tel1}</td>
        </tr>
        <tr>
          <th>電話番号2</th>
          <td>
          {dataApplication.apply.j_tel2 &&
            <>
            {dataApplication.apply.j_tel2}
            </>
            }
          </td>
        </tr>
        <tr>
          <th>FAX</th>
          <td>
          {dataApplication.apply.j_fax &&
            <>
            {dataApplication.apply.j_fax}
            </>
            }
          </td>
        </tr>
        <tr>
          <th>メールアドレス</th>
          <td>{dataApplication.apply.j_email}</td>
        </tr>
      </tbody>
      </table>
      }

      <h6>【公演計画情報】</h6>
      <table className="Form" summary="#">
        <tbody><tr>
          <th>主催者の概要 <span>※必須</span></th>
          <td>
          {dataApplication.plan.dtype=="1" && "行政"}
          {dataApplication.plan.dtype=="2" && "楽団"}
          {dataApplication.plan.dtype=="3" && "音楽事務所"}
          {dataApplication.plan.dtype=="4" && "アマチュア団体"}
          {dataApplication.plan.dtype=="5" && "個人"}
          {dataApplication.plan.dtype=="6" && "NPO"}
          {dataApplication.plan.dtype=="7" && "法人"}
          {dataApplication.plan.dtype=="8" && "その他"}
          {dataApplication.plan.dtype=="9" && "教育機関"}

          &nbsp;

          {dataApplication.plan.dtype=="9" &&
          <>
            {dataApplication.plan.dtype1=="1" && "公立（横浜市立）"}
            {dataApplication.plan.dtype1=="2" && "公立（県立）"}
            {dataApplication.plan.dtype1=="3" && "公立（他）"}
            {dataApplication.plan.dtype1=="4" && "私立"}
          </>
          }

          &nbsp;

          {dataApplication.plan.dtype=="9" &&
          <>
            {dataApplication.plan.dtype2=="1" && "幼稚園"}
            {dataApplication.plan.dtype2=="2" && "小学校"}
            {dataApplication.plan.dtype2=="3" && "中学校"}
            {dataApplication.plan.dtype2=="4" && "高校"}
            {dataApplication.plan.dtype2=="5" && "大学"}
            {dataApplication.plan.dtype2=="6" && "その他学校"}
          </>
          }
          </td>
        </tr>
        <tr>
          <th rowSpan="4">当ホール使用履歴 <span>※必須</span></th>
          <td>
          {dataApplication.plan.history==1 && "初申請"}
          {dataApplication.plan.history==2 && "申請は2回目以上だが、主催としての利用実績無し"}
          {dataApplication.plan.history==3 && "主催として同団体で利用実績あり"}

          {dataApplication.plan.history==3 &&
          <>
            {dataApplication.plan.history1==1 && <>1回</>}
            {dataApplication.plan.history1==2 && <>2回</>}
            {dataApplication.plan.history1==3 && <>3回以上</>}
          </>
          }
        </td>
        </tr>
        <tr>
          <th style={{display: "none"}}>当ホール使用履歴</th>
          <td>
            <span>※初申請の場合、団体の活動履歴・開催希望公演の実績（他ホールでのチラシ・プログラムなど）を1つご提出ください。</span><br />
            {dataApplication.plan.file &&  <span>(<a href={process.env.REACT_APP_API_ENDPOINT + 'my/application/' + id + '/file/' + dataApplication.plan.file}>ファイルあり</a>)</span>}
          </td>
        </tr>
        <tr>
          <th style={{display: "none"}}>当ホール使用履歴</th>
          <td>
            <span>※まだ主催としてご利用実績がない団体の方のみお答えください。</span><br />
            {dataApplication.plan.past==1 && "他主催公演に同団体として出演実績あり"}
            {dataApplication.plan.pastText}
          </td>
        </tr>
        <tr>
          <th style={{display: "none"}}>当ホール使用履歴</th>
          <td>
            <span>他ホールでの公演実績</span><br />
            { dataApplication.plan.pastOther.map((item) => (
              <>
              <div key={item.id}>{item.text}</div>
              </>
            ))}
          </td>
        </tr>
        <tr>
          <th>公演名<span>※必須</span></th>
          <td>{dataApplication.plan.title}</td>
        </tr>
        <tr>
          <th>入場料金等の最高額<span>※必須</span></th>
          <td>
            {dataApplication.venue==10 &&
            <>
            {dataApplication.plan.price==1 && "無料～2,000円"}
            {dataApplication.plan.price==2 && "2,001円～5,000円"}
            {dataApplication.plan.price==3 && "5,001円以上"}
            <br />
            </>
            }

            {dataApplication.venue==20 &&
            <>
            {dataApplication.plan.price==1 && "無料～3,000円"}
            {dataApplication.plan.price==2 && "3,001円以上"}
            <br />
            </>
            }
            <span>※入場料金等のうちで最高額を選択してください。※当日増も含め、料金の最高額によって利用料金が決まります。</span>
          </td>
        </tr>
        <tr>
          <th>対象入場者<span>※必須</span></th>
          <td>
            {dataApplication.plan.target=="1" && "一般"}
            {dataApplication.plan.target=="2" && "参加者のみ"}
            {dataApplication.plan.target=="3" && "関係者のみ"}
          </td>
        </tr>
        <tr>
          <th>入場方式<span>※必須</span></th>
          <td>
          {dataApplication.plan.ticket=="1" && "指定席"}
          {dataApplication.plan.ticket=="2" && "当日指定引換"}
          {dataApplication.plan.ticket=="3" && "自由席（チケット発行あり）"}
          {dataApplication.plan.ticket=="4" && "自由席（チケット発行なし）"}
          </td>
        </tr>
        <tr>
          <th>出演者数<span>※必須</span></th>
          <td>{dataApplication.plan.players}名</td>
        </tr>
        <tr>
          <th>出演者/演奏団体</th>
          <td>
            { dataApplication.plan.player.map((item) => (
            <div key={item.id}>{item.text}</div>
            ))}
          </td>
        </tr>
        <tr>
          <th>予定曲目</th>
          <td>
            { dataApplication.plan.programs.map((item) => (
            <div key={item.id}>{item.text}</div>
            ))}
          </td>
        </tr>
        <tr>
          <th>公演ジャンル<span>※必須</span></th>
          <td>
          {dataApplication.plan.genre=="1" && "クラシック音楽"}
          {dataApplication.plan.genre=="2" && "ポピュラー音楽"}
          {dataApplication.plan.genre=="3" && "その他の音楽"}
          </td>
        </tr>
        <tr>
          <th rowSpan="6">公演の概要</th>
          <td>
            <span className="ttl">使用楽器等</span><br />
            {dataApplication.plan.part.includes("1") && <>声楽<br/></>}
            {dataApplication.plan.part.includes("2") && <>弦楽器<br/></>}
            {dataApplication.plan.part.includes("3") && <>管楽器<br/></>}
            {dataApplication.plan.part.includes("4") && <>鍵盤楽器<br/></>}
            {dataApplication.plan.part.includes("5") && <>打楽器<br/></>}
            {dataApplication.plan.part.includes("6") && "合唱"}

            {dataApplication.plan.part.includes("6") &&
            <>
              &nbsp;
              {dataApplication.plan.part6=="1" && "(女声)"}
              {dataApplication.plan.part6=="2" && "(男声)"}
              {dataApplication.plan.part6=="3" && "(混声)"}
              {dataApplication.plan.part6=="4" && "(児童)"}
            </>
            }
          </td>
        </tr>
        <tr>
          <th style={{display: "none"}}>公演の概要</th>
          <td>
            <span className="ttl">実施体制</span><br />
            {dataApplication.plan.type=="1" && "1団体"}
            {dataApplication.plan.type=="2" &&
              <>
              "その他"
              {dataApplication.plan.type2}
              </>
            }
            {dataApplication.plan.type=="3" && "複数団体（教室）合同"}
            <br />
            <div style={{whiteSpace:"pre-wrap"}}>
            {dataApplication.plan.type3}
            </div>
            </td>
        </tr>
        <tr>
          <th style={{display: "none"}}>公演の概要</th>
          <td>
            <span className="ttl">編成</span><br />
            {dataApplication.plan.form=="1" && "ソロ"}
            {dataApplication.plan.form=="2" && "アンサンブル"}
            {dataApplication.plan.form=="3" && "オーケストラ・吹奏楽・合唱（80名未満）"}
            {dataApplication.plan.form=="4" && "オーケストラ・吹奏楽・合唱（80名以上）"}
          </td>
        </tr>
        <tr>
          <th style={{display: "none"}}>公演の概要</th>
          <td>
            <span className="ttl">内容</span><br />
            {dataApplication.plan.program=="1" && "演奏会"}
            {dataApplication.plan.program=="2" && "フェスティバル・大会"}
            {dataApplication.plan.program=="3" && "その他"}
              {dataApplication.plan.programText}
            {dataApplication.plan.program=="4" && <>発表会<br /></>}
              {dataApplication.plan.recital.includes("1") && <>&nbsp; 幼児<br/></>}
              {dataApplication.plan.recital.includes("2") && <>&nbsp; 小中学生<br/></>}
              {dataApplication.plan.recital.includes("3") && <>&nbsp; 高校生<br/></>}
              {dataApplication.plan.recital.includes("4") && <>&nbsp; 大学〜大学院生<br/></>}
              {dataApplication.plan.recital.includes("5") && <>&nbsp; 成年<br/></>}
          </td>
        </tr>
        <tr>
          <th style={{display: "none"}}>公演の概要</th>
          <td>
            {dataApplication.plan.pa=="1" && "PAなし"}
            {dataApplication.plan.pa=="2" && "PAあり（主催者業者手配あり）"}
            {dataApplication.plan.pa=="3" && "PAあり（主催者業者手配なし）"}
          </td>
        </tr>
        <tr>
          <th style={{display: "none"}}>公演の概要</th>
          <td>
            {dataApplication.plan.lighting=="1" && "照明演出なし"}
            {dataApplication.plan.lighting=="2" && "照明演出あり（主催者業者手配あり）"}
            {dataApplication.plan.lighting=="3" && "照明演出あり（主催者業者手配なし）"}
          </td>
        </tr>
        <tr>
          <th rowSpan={dataApplication.venue==10 ? 2 : 1}>ホール所有楽器の使用希望</th>
          <td>
            <span>※ご希望の使用機種を入力してください。ホール利用状況によりご希望に沿えない場合があります。パイプオルガンの使用には別途使用申込書が必要です。<br /></span>
            <br />
            {dataApplication.plan.piano==1 &&
              <>

                フルコンサートグランドピアノ
                &nbsp;
                {dataApplication.plan.piano_num=="1" && "1台"}
                {dataApplication.plan.piano_num=="2" && "2台"}
                <br />

              1台目 &nbsp;
              {dataApplication.plan.piano1=="0" && "機種指定なし"}
              {dataApplication.plan.piano1=="1" && "スタインウェイD274"}
              {dataApplication.plan.piano1=="2" && "ベーゼンドルファーインペリアル"}
              {dataApplication.plan.piano1=="3" && "ヤマハCFX"}
              <br />

              {dataApplication.plan.piano_num=="2" &&
              <>
              2台目 &nbsp;
              {dataApplication.plan.piano2=="0" && "機種指定なし"}
              {dataApplication.plan.piano2=="1" && "スタインウェイD274"}
              {dataApplication.plan.piano2=="2" && "ベーゼンドルファーインペリアル"}
              {dataApplication.plan.piano2=="3" && "ヤマハCFX"}
              <br />
              </>
              }

              </>
            }
          </td>
        </tr>
        {dataApplication.venue==10 &&
        <tr>
          <th style={{display: "none"}}>ホール所有楽器の使用希望</th>
          <td>
          {dataApplication.plan.organ==1 &&
            <>
            パイプオルガン
            &nbsp;
            {dataApplication.plan.organist=="1" && "オルガニスト確定"}
            {dataApplication.plan.organist=="2" && "オルガニスト未定"}
            {dataApplication.plan.organist=="3" && "オルガニストの斡旋希望"}

            {dataApplication.plan.organist=="1" &&
            <>
              &nbsp;
              {dataApplication.plan.organistname}
            </>
            }
            </>
          }
          </td>
        </tr>
        }
        <tr>
          <th>抽選会への参加 <span>※必須</span></th>
          <td>
            <span>抽選会への参加を希望されますか。</span><br />
            {dataApplication.plan.lottery=="0" && "希望しません（ホール代行抽選）"}
            {dataApplication.plan.lottery=="1" && "希望します（来館抽選）"}
          </td>
        </tr>
        <tr>
          <th>備考メモ欄</th>
          <td>
            <div style={{whiteSpace:"pre-wrap"}}>
            {dataApplication.plan.comment}
            </div>
          </td>
        </tr>

      </tbody>
      </table>

      <table className="Form" summary="#">
        <tbody>
        <tr>
            <th>申し込み番号</th>
            <td>{dataApplication.no}</td>
          </tr>
          <tr>
            <th>申し込み日</th>
            <td>{dataApplication.cdatetime}</td>
          </tr>
          <tr>
            <th>最終更新日</th>
            <td>{dataApplication.mdatetime}</td>
          </tr>
          {dataApplication.win > 10 && 
          <tr>
            <th>抽選結果</th>
            <td>
              {dataApplication.win == 11 && '第一希望当選'}
              {dataApplication.win == 12 && '第二希望当選'}
              {dataApplication.win == 13 && '第三希望当選'}
              {dataApplication.win == 14 && '落選'}
            </td>
          </tr>
          }
        </tbody>
      </table>

      </>
      :
      <>
        <div className="txt">
          <p>
            読み込み中...
          </p>
        </div>
      </>
      }

      <div className="Form__btnbox">
      <div className="Form__btn-return">
          <input type="button" value="戻る" onClick={() => navigate(-1)} />
        </div>
        <div className="Form__btn-return">
        <a href={instance2.url} download={"公演計画書" + dataApplication.no + ".pdf"}>公演計画書ダウンロード</a>
        </div>
        <div className="Form__btn-return">
          <a href={instance.url} download={"利用許可申請書" + dataApplication.no + ".pdf"}>利用許可申請書ダウンロード</a>
        </div>
      </div>
    </>
  );

}
