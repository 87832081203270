import { React, useEffect, useState } from "react";
import { useParams ,Link} from "react-router-dom";
import { useMyData } from './MyProvider';
import { useInput } from "./hooks";

export default function Password() {
  let {forgot, uuid, mail} = useParams();

  const { updatePasswd } = useMyData();

  const [passwordProps, resetPassword] = useInput(""); // パスワード
  const [password1Props, resetPassword1] = useInput(""); // パスワード確認用

  const [hasError, setHasError] = useState(true); // エラーチェック
  const [retStatus, setRetStatus] = useState(""); // 送信結果

  const [passwdCheck1, setPasswdCheck1] = useState(false); // パスワードチェック
  const [passwdCheck2, setPasswdCheck2] = useState(false); // パスワードチェック
  const [passwdCheck3, setPasswdCheck3] = useState(false); // パスワードチェック


  // パスワード内容チェック
  useEffect(() => {
    if ( passwordProps.value && passwordProps.value !== ''){
      if ( passwordProps.value.length >= 6 && passwordProps.value.length <= 32 ) {
        if ( passwordProps.value.search(/[0-9]/)>=0 && passwordProps.value.search(/[^0-9]/)>=0 ) {
          setPasswdCheck1(true);
        } else {
          setPasswdCheck1(false);
        }
      } else {
        setPasswdCheck1(false);
      }
    } else {
      setPasswdCheck1(true);
    }

    const words = mail.split('@');

    if ( passwordProps.value && passwordProps.value !== ''){
      if ( passwordProps.value.indexOf(words[0])>=0 ) {
        setPasswdCheck3(false);
      } else {
        setPasswdCheck3(true);
      }
    } else {
      setPasswdCheck3(true);
    }



  },[passwordProps.value]);

  // パスワード一致チェック
  useEffect(() => {
    if ( passwordProps.value !== ''){
      if ( passwordProps.value === password1Props.value ) {
        setPasswdCheck2(true);
      } else {
        setPasswdCheck2(false);
      }
    } else {
      setPasswdCheck2(true);
    }
  },[passwordProps.value, password1Props.value]);

  // エラーチェック
  useEffect(() => {
    let err = false;

    if(passwordProps.value=='') err=true;

    // 条件判定
    if(!passwdCheck1) err=true;
    if(!passwdCheck2) err=true;
    if(!passwdCheck3) err=true;

    setHasError(err);
  });


  // ログイン
  const submit = (e) => {
    e.preventDefault();
    setRetStatus("WAITING");
    updatePasswd(passwordProps.value, uuid, forgot, mail, setRetStatus);
  }



  return (
    <>
      <div className="Mypagelogin">
      <h1 className="pagetopLabel">パスワード再設定</h1>
      <p>新しいパスワードを入力してください。</p>
      <form onSubmit={submit}>
        <table className="Form" summary="#">
        <tbody>
          <tr>
            <th>パスワード <span>※必須</span></th>
            <td><input {...passwordProps} size="50" className="w_60" type="password" required pattern="^[0-9a-za-z\-\$\#\[\]\(\)\_]{6,32}$" /><span>（半角英数字で8文字以上40文字以内）</span><br/>
            { !passwdCheck1  && <span>6文字以上32文字以内で数字とアルファベットを含んで入力ください。</span>}
            { !passwdCheck3  && <span className="notice">このパスワードは使用できません。</span>}
            </td>
          </tr>
          <tr>
            <th>パスワード（確認） <span>※必須</span></th>
            <td><input {...password1Props} size="50" className="w_60" type="password" required pattern="^[0-9a-za-z\-\$\#\[\]\(\)\_]{6,32}$" /><span>（半角英字と半角数字を含む、6文字以上32文字以内）</span><br/>
              { !passwdCheck2  && <span className="notice">パスワードが一致しません</span>}
            </td>
          </tr>
        </tbody>
        </table>

        { retStatus==='WAITING' && <>設定中</> }
        { retStatus==='OK' && <>パスワードを再設定しました。</> }
        { retStatus==='NG' && <><span className="notice">パスワード再設定ができませんでした。<br />アドレス(URL)の有効時間が切れているためと考えられます。<br />もう一度、パスワード再設定の手続きを行ってください。<br /><a href="https://mypage.yokohama-minatomiraihall.jp/forgot">パスワード再設定を行う。</a></span></> }

        <div className="Form__btnbox">
          <div className="Form__btn-confirm">
          { retStatus==='OK' ?
            <Link to='/' title="https://mypage.yokohama-minatomiraihall.jp/">戻る</Link>
            :
            <input value="更新" type="submit" id="next" disabled={ hasError } />
          }
          </div>
        </div>

      </form>
      </div>
    </>
 );
}
