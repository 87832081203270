import { React, useEffect, useState } from 'react';
import { Document, Page, Text, View, StyleSheet, Font } from '@react-pdf/renderer';
import { Grid, Col } from "./atoms/Grid";

export default function ApplyDetailPdf(props) {

  Font.register({
    family: "NotoSansJP",
    src: "/css/fonts/NotoSansJP-Regular.otf",
  });


  // Create styles
  const styles = StyleSheet.create({
    page: {
      flexDirection: 'row',
      backgroundColor: '#E4E4E4'
    },
    section: {
      margin: "8mm 5mm",
      padding: 10,
      flexGrow: 1,
      fontFamily: 'NotoSansJP',
      fontSize: '3.0mm',
    },
    select: {
      border:"1px solid #000",
      borderRadius:"3mm",
    }
  });



  const getDataStr = (req) => {
    let dataStr = "";
    if(req.date) {
      dataStr += `${props.data.venue==10 ? '大' : '小'}ホール　`;
      dataStr += `${props.data.venue==10 && props.data.req1.seat==1 ? '（A型：全階利用）　' : ''}`;
      dataStr += `${props.data.venue==10 && props.data.req1.seat==2 ? '（B型：1階のみ）　' : ''}`;

      let term = [];
      if(req.time1 > 0) term.push('午前');
      if(req.time2 > 0) term.push('午後');
      if(req.time3 > 0) term.push('夜間');

      dataStr += req.date_t + "　" + term.join("・");
    }
    return dataStr;
  }
  const getOptionStr = (req) => {
    let retStr = "";
    if(req.date) {
      let room = [];
      if(req.option1 > 0) room.push('大ホール');
      if(req.option2 > 0) room.push('小ホール');
      if(req.option3 > 0) room.push('レセプションルーム');
      if(req.option4 > 0) room.push('リハーサル室');
      if(req.proom1 > 0) room.push('練習室1');
      if(req.proom2 > 0) room.push('練習室2');
      if(req.proom3 > 0) room.push('練習室3');
      if(req.proom4 > 0) room.push('練習室4');
      if(req.proom5 > 0) room.push('練習室5');
      if(req.proom6 > 0) room.push('練習室6');

      if(room.length > 0) {
        retStr = '併設利用 ： ' + room.join("・");
      }
    }
    return retStr;
  }

  const getOptionGuests = (req) => {
    let retStr = "";
    if(req.guests) {
      retStr = '集客見込 ： ' + req.guests + "名程度";
    }
    return retStr;
  }

  const getTime1 = () => {
    const dataApplication = props.data;
    if (dataApplication.req1.before_over_time > 0) {
      if(dataApplication.req1.time1 > 0) {
        if (dataApplication.req1.before_over_time == 30) {
          return '（08:30〜09:00）';
        } else {
          return '（08:00〜09:00）';
        }
      } else {
        if (dataApplication.req1.before_over_time == 30) {
          return '（12:30〜13:00）';
        } else {
          return '（12:00〜13:00）';
        }
      }
    } else if(dataApplication.req1.after_over_time > 0 ){
      if(dataApplication.req1.time3 > 0) {
        if (dataApplication.req1.after_over_time == 30) {
          return '（22:00〜22:30）';
        } else {
          return '（22:00〜23:00）';
        }
      } else {
        if (dataApplication.req1.after_over_time == 30) {
          return '（16:30〜17:00）';
        } else {
          return '（16:30〜17:30）';
        }
      }
    } else {
      return '（　　:　　〜　　:　　）';
    }
  }
  const getTime2 = () => {
    const dataApplication = props.data;
    if (dataApplication.req1.before_over_time > 0 && dataApplication.req1.after_over_time > 0 ){
      if(dataApplication.req1.time3 > 0) {
        if (dataApplication.req1.after_over_time == 30) {
          return '（22:00〜22:30）';
        } else {
          return '（22:00〜23:00）';
        }
      } else {
        if (dataApplication.req1.after_over_time == 30) {
          return '（16:30〜17:00）';
        } else {
          return '（16:30〜17:30）';
        }
      }
    } else {
      return '（　　:　　〜　　:　　）';
    }
  }

  if (props.data && props.data.req1 ) {
    const dataApplication = props.data;
    return (
      <Document>
      <Page size="A4">
        <View style={styles.section}>
          <Text>第2号様式（第5条 第1項）</Text>
          <Grid borderTop borderLeft>
            <Col borderRight>
              <Text style={{ fontSize: '5mm', letterSpacing: '.5mm' }}>横浜みなとみらいホール   <Text style={{ fontSize: '5mm', letterSpacing: '1.5mm' }}>利用許可申請書</Text></Text>
              <Grid>
                <Col padding="1mm 0" size="10"><Text style={{ fontSize: '3.5mm', letterSpacing: '1mm' }}>横浜みなとみらいホール指定管理者</Text></Col>
                <Col padding="1mm 2mm" style={{ textAlign: "right", width: '50mm' }}><Text>　　　年　　　月　　　日</Text></Col>
              </Grid>
              <Text>横浜みなとみらいホールの施設及び付帯設備を利用したいので、次のとおり申請します。</Text>
            </Col>
          </Grid>
  
          <Grid borderTop borderLeft>
            <Col
              borderRight
              style={{
                width: '35mm',
                justifyContent: "center"
              }}
            >
              <Text>申請者</Text>
              <Text>（主催者）</Text>
            </Col>
            <Col padding="0" size="6"
              style={{
                width: '165mm',
                justifyContent: "center"
              }}
            >
              <Grid borderBottom>
                <Col borderRight>
                  <Text>団体名　　　　　{dataApplication.apply.r_name}</Text>
                </Col>
              </Grid>
              <Grid borderBottom>
                <Col borderRight>
                  <Text>代表者名　　　　{dataApplication.apply.r_d_l_name} {dataApplication.apply.r_d_f_name}</Text>
                </Col>
                <Col borderRight>
                  <Text>担当者名　　　　{!dataApplication.apply.check1.includes("no") && dataApplication.apply.r_t_l_name + ' ' +  dataApplication.apply.r_t_f_name }</Text>
                </Col>
              </Grid>
              <Grid borderBottom>
                <Col borderRight>
                  <Text>住所　　　　　
                    {dataApplication.apply.check2.includes("no") ?
                    <>〒{dataApplication.apply.r_d_zipcode}　{dataApplication.apply.r_d_address}</>
                    :
                    <>〒{dataApplication.apply.r_t_zipcode}　{dataApplication.apply.r_t_address}</>
                    }
                  </Text>
                </Col>
              </Grid>
              <Grid>
                <Col borderRight>
                  <Text>TEL　　　　　　{dataApplication.apply.check1.includes("no") ? dataApplication.apply.r_d_tel1 : dataApplication.apply.r_t_tel1}</Text>
                </Col>
                <Col borderRight>
                  <Text>FAX　　　　　　{dataApplication.apply.check1.includes("no") ? dataApplication.apply.r_d_fax : dataApplication.apply.r_t_fax}</Text>
                </Col>
              </Grid>
            </Col>
          </Grid>
  
          <Grid borderTop borderLeft>
            <Col
              borderRight
              style={{
                width: '35mm',
                justifyContent: "center"
              }}
            >
              <Text>事務代行者</Text>
            </Col>
            <Col padding="0" size="6"
              style={{
                width: '165mm',
                justifyContent: "center"
              }}
            >
              <Grid borderBottom>
                <Col borderRight>
                  <Text>団体名　　　　　{dataApplication.apply.check3.includes("yes") && dataApplication.apply.j_name}</Text>
                </Col>
              </Grid>
              <Grid borderBottom>
                <Col borderRight>
                  <Text>代表者名　　　　{dataApplication.apply.check3.includes("yes") && dataApplication.apply.j_d_l_name + ' ' + dataApplication.apply.j_d_f_name }</Text>
                </Col>
                <Col borderRight>
                  <Text>担当者名　　　　{dataApplication.apply.check3.includes("yes") && dataApplication.apply.j_t_l_name + ' ' + dataApplication.apply.j_t_f_name }</Text>
                </Col>
              </Grid>
              <Grid borderBottom>
                <Col borderRight>
                  <Text>住所　　　　　
                  {dataApplication.apply.check3.includes("yes") && 
                  <>〒{dataApplication.apply.j_zipcode}　{dataApplication.apply.j_address}</>
                  }
                  </Text>
                </Col>
              </Grid>
              <Grid>
                <Col borderRight>
                  <Text>TEL　　　　　　{dataApplication.apply.check3.includes("yes") && dataApplication.apply.j_tel1}</Text>
                </Col>
                <Col borderRight>
                  <Text>FAX　　　　　　{dataApplication.apply.check3.includes("yes") && dataApplication.apply.j_fax}</Text>
                </Col>
              </Grid>
            </Col>
          </Grid>
  
          <Grid borderTop borderLeft>
            <Col
              borderRight
              style={{
                width: '35mm',
                justifyContent: "center"
              }}
            >
              <Text>利用希望日時</Text>
            </Col>
            <Col padding="0" size="6"
              style={{
                width: '165mm',
                justifyContent: "center"
              }}
            >
              <Grid borderBottom style={{ borderBottomStyle: 'dashed' }}>
                <Col borderRight size="6" style={{ width: '115mm' }}>
                  <Text>第１希望 　　{dataApplication.req1.date_t ? dataApplication.req1.date_t : '　 　年 　 月 　 日（　）'}</Text>
                </Col>
                <Col borderRight size="2" style={{ textAlign: "right", width: '50mm' }}>
                  <Text>{dataApplication.req1.start_time ? dataApplication.req1.start_time.substr(0,2) : ''} 時 {dataApplication.req1.start_time ? dataApplication.req1.start_time.substr(3,2) : '　'} 分開演</Text>
                </Col>
              </Grid>
              <Grid borderBottom style={{ borderBottomStyle: 'dashed' }}>
                <Col borderRight size="6" style={{ width: '115mm' }}>
                  <Text>第２希望 　　{dataApplication.req2.date_t ? dataApplication.req2.date_t : '　 　年 　 月 　 日（　）'}</Text>
                </Col>
                <Col borderRight size="2" style={{ textAlign: "right", width: '50mm' }}>
                <Text>{dataApplication.req2.start_time ? dataApplication.req2.start_time.substr(0,2) : ''} 時 {dataApplication.req2.start_time ? dataApplication.req2.start_time.substr(3,2) : '　'} 分開演</Text>
                </Col>
              </Grid>
              <Grid>
                <Col borderRight size="6" style={{ width: '115mm' }}>
                  <Text>第３希望 　　{dataApplication.req3.date_t ? dataApplication.req3.date_t : '　 　年 　 月 　 日（　）'}</Text>
                </Col>
                <Col borderRight size="2" style={{ textAlign: "right", width: '50mm' }}>
                <Text>{dataApplication.req3.start_time ? dataApplication.req3.start_time.substr(0,2) : ''} 時 {dataApplication.req3.start_time ? dataApplication.req3.start_time.substr(3,2) : '　'} 分開演</Text>
                </Col>
              </Grid>
            </Col>
          </Grid>
  
          <Grid borderTop borderLeft>
            <Col
              borderRight
              style={{
                width: '35mm',
                justifyContent: "center"
              }}
            >
              <Text>※ 利用日時</Text>
            </Col>
            <Col padding="0" size="6"
              style={{
                width: '165mm',
                justifyContent: "center"
              }}
            >
              <Grid>
                <Col borderRight size="6" style={{ width: '115mm' }}>
                  <Text>　　　　 　　　 　年 　 月 　 日（　）</Text>
                </Col>
                <Col borderRight size="2" style={{ textAlign: "right", width: '50mm' }}>
                  <Text>　　時 　 分開演</Text>
                </Col>
              </Grid>
            </Col>
          </Grid>
  
          <Grid borderTop borderLeft>
            <Col
              borderRight
              style={{
                width: '35mm',
                justifyContent: "center"
              }}
            >
              <Text>催物の名称</Text>
              <Text>又は公演名</Text>
            </Col>
            <Col padding="0" size="6"
              style={{
                width: '165mm',
                justifyContent: "center"
              }}
            >
              <Grid style={{ height: "12mm" }}>
                <Col borderRight size="6" style={{ width: '100mm', justifyContent: "center" }}>
                  <Text>　　{dataApplication.plan.title.substr(0,20)}</Text>
                </Col>
                <Col borderRight size="2" style={{ width: '55mm', justifyContent: "center" }}>
                  <Text>20文字以内でご記入下さい。</Text>
                </Col>
              </Grid>
            </Col>
          </Grid>
  
          <Grid borderTop borderLeft>
            <Col
              borderRight
              style={{
                width: '35mm',
                justifyContent: "center"
              }}
            >
              <Text>主な出演者</Text>
            </Col>
            <Col padding="0" size="6"
              style={{
                width: '165mm',
                justifyContent: "center"
              }}
            >
              <Grid>
                <Col size="6" style={{ width: '100mm', justifyContent: "center" }}>
                  <Text>　　{dataApplication.plan.player.length > 0 ? dataApplication.plan.player[0].text : ''}</Text>
                </Col>
                <Col borderRight size="2" style={{ textAlign: "right", width: '55mm', justifyContent: "center" }}>
                  <Text>ほか　計　{dataApplication.plan.players}　名</Text>
                </Col>
              </Grid>
            </Col>
          </Grid>
  
          <Grid borderTop borderLeft>
            <Col
              borderRight
              style={{
                width: '35mm',
                justifyContent: "center"
              }}
            >
              <Text>利用施設</Text>
            </Col>
            <Col padding="0" size="6"
              style={{
                width: '165mm',
                justifyContent: "center"
              }}
            >
              <Grid>
                <Col borderBottom style={{ borderBottomStyle: 'dashed', width: '83mm' }} size="3">
                  <Text style={dataApplication.venue==10 && dataApplication.req1.seat==1 ? styles.select : undefined}>　大ホールＡ型（1, 2, 3 階席利用）</Text>
                </Col>
                <Col borderRight borderBottom style={{ borderBottomStyle: 'dashed', width: '82mm' }} size="3">
                  <Text style={dataApplication.req1.option4>0 ? styles.select : undefined}>　リハーサル室</Text>
                </Col>
              </Grid>
              <Grid>
                <Col borderBottom style={{ borderBottomStyle: 'dashed', width: '83mm' }} size="3">
                  <Text style={dataApplication.venue==10 && dataApplication.req1.seat==2 ? styles.select : undefined}>　大ホールＢ型（1階席のみ利用）</Text>
                </Col>
                <Col borderRight borderBottom style={{ borderBottomStyle: 'dashed', width: '82mm' }} size="3">
                  <Text style={dataApplication.req1.option3>0 ? styles.select : undefined}>　レセプションルーム</Text>
                </Col>
              </Grid>
              <Grid>
                <Col size="3" style={{ width: '83mm' }}>
                  <Text style={dataApplication.venue==20 || dataApplication.req1.option2>0 ? styles.select : undefined}>　小ホール</Text>
                </Col>
                <Col size="3" style={{ width: '21mm' }}>
                  <Text>　音楽練習室</Text>
                </Col>
                <Col size="3" style={{ width: '9mm' }}>
                  <Text style={dataApplication.req1.proom1>0 ? styles.select : undefined}>　1　</Text>
                </Col>
                <Col size="3" style={{ width: '9mm' }}>
                  <Text style={dataApplication.req1.proom2>0 ? styles.select : undefined}>　2　</Text>
                </Col>
                <Col size="3" style={{ width: '9mm' }}>
                  <Text style={dataApplication.req1.proom3>0 ? styles.select : undefined}>　3　</Text>
                </Col>
                <Col size="3" style={{ width: '9mm' }}>
                  <Text style={dataApplication.req1.proom4>0 ? styles.select : undefined}>　4　</Text>
                </Col>
                <Col size="3" style={{ width: '9mm' }}>
                  <Text style={dataApplication.req1.proom5>0 ? styles.select : undefined}>　5　</Text>
                </Col>
                <Col size="3" style={{ width: '9mm' }}>
                  <Text style={dataApplication.req1.proom6>0 ? styles.select : undefined}>　6　</Text>
                </Col>
                <Col borderRight size="3" style={{ width: '7mm' }}>
                </Col>
              </Grid>
            </Col>
          </Grid>
  
          <Grid borderTop borderLeft>
            <Col
              borderRight
              style={{
                width: '35mm',
                justifyContent: "center"
              }}
            >
              <Text>利用区分</Text>
            </Col>
            <Col padding="0" size="6"
              style={{
                width: '165mm',
                justifyContent: "center"
              }}
            >
              <Grid>
                <Col borderBottom style={{ borderBottomStyle: 'dashed', width: '83mm' }} size="3">
                  <Text style={dataApplication.req1.time1>0 ? styles.select : undefined}>　午前の部　　　（09:00〜12:00）</Text>
                </Col>
                <Col borderBottom style={{ borderBottomStyle: 'dashed', width: '41mm' }} size="3">
                  <Text style={dataApplication.req1.time1==2 ? styles.select : undefined}>　準備　・　リハーサル　</Text>
                </Col>
                <Col borderRight borderBottom style={{ borderBottomStyle: 'dashed', width: '41mm' }} size="3">
                  <Text style={dataApplication.req1.time1==1 ? styles.select : undefined}>　本公演</Text>
                </Col>
              </Grid>
              <Grid>
                <Col borderBottom style={{ borderBottomStyle: 'dashed', width: '83mm' }} size="3">
                  <Text style={dataApplication.req1.time2>0 ? styles.select : undefined}>　午後の部　　　（13:00〜16:30）</Text>
                </Col>
                <Col borderBottom style={{ borderBottomStyle: 'dashed', width: '41mm' }} size="3">
                  <Text style={dataApplication.req1.time2==2 ? styles.select : undefined}>　準備　・　リハーサル　</Text>
                </Col>
                <Col borderRight borderBottom style={{ borderBottomStyle: 'dashed', width: '41mm' }} size="3">
                  <Text style={dataApplication.req1.time2==1 ? styles.select : undefined}>　本公演</Text>
                </Col>
              </Grid>
              <Grid>
                <Col borderBottom size="3" style={{ width: '83mm' }}>
                  <Text style={dataApplication.req1.time3>0 ? styles.select : undefined}>　夜間の部　　　（17:30〜22:00）</Text>
                </Col>
                <Col borderBottom style={{ width: '41mm' }} size="3">
                  <Text style={dataApplication.req1.time3==2 ? styles.select : undefined}>　準備　・　リハーサル　</Text>
                </Col>
                <Col borderRight borderBottom style={{ width: '41mm' }} size="3">
                  <Text style={dataApplication.req1.time3==1 ? styles.select : undefined}>　本公演</Text>
                </Col>
              </Grid>
              <Grid>
                <Col size="3" style={{ width: '83mm' }}>
                  <Text style={dataApplication.req1.before_over_time > 0 || dataApplication.req1.after_over_time > 0 ? styles.select : undefined}>
                    　区分時間帯以外 
                    {getTime1()}
                  </Text>
                </Col>
                <Col style={{ width: '41mm' }} size="3">
                  <Text>　準備　・　リハーサル　</Text>
                </Col>
                <Col borderRight style={{ width: '41mm' }} size="3">
                  <Text>　本公演</Text>
                </Col>
              </Grid>
              <Grid>
                <Col size="3" style={{ width: '83mm' }}>
                  <Text style={dataApplication.req1.before_over_time > 0 && dataApplication.req1.after_over_time > 0 ? styles.select : undefined}>
                    　　　　　　　　
                    {getTime2()}
                  </Text>
                </Col>
                <Col style={{ width: '41mm' }} size="3">
                  <Text>　準備　・　リハーサル　</Text>
                </Col>
                <Col borderRight style={{ width: '41mm' }} size="3">
                  <Text>　本公演</Text>
                </Col>
              </Grid>
            </Col>
          </Grid>
  
          <Grid borderTop borderLeft borderRight>
            <Col
              style={{
                width: '35mm',
                justifyContent: "center"
              }}
            >
              <Text>※（利用施設内訳）</Text>
            </Col>
            <Col padding="0" size="6"
              style={{
                width: '165mm',
                justifyContent: "center"
              }}
            >
            </Col>
          </Grid>
  
  
  
          <Grid borderTop borderLeft borderRight>
            <Col
              borderRight
              style={{
                width: '35mm',
                justifyContent: "center"
              }}
            >
              <Text>利用区分</Text>
            </Col>
            <Col padding="0"
              style={{
                width: '34mm',
                justifyContent: "center",
                textAlign: "center"
              }}
            >
              <Grid borderBottom>
                <Col padding="0"
                  borderRight
                  style={{
                    width: '34mm',
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                >
                  <Text>大ホール</Text>
                </Col>
              </Grid>
              <Grid>
                <Col padding="0"
                  borderRight
                  style={{
                    width: '17mm',
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                >
                  <Text>Ａ 型</Text>
                </Col>
                <Col padding="0"
                  borderRight
                  style={{
                    width: '17mm',
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                >
                  <Text>Ｂ 型</Text>
                </Col>
              </Grid>
            </Col>
            <Col padding="0"
              borderRight
              style={{
                width: '17mm',
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <Text>小ホール</Text>
            </Col>
            <Col padding="0"
              borderRight
              style={{
                width: '18mm',
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <Text>利用内容</Text>
            </Col>
            <Col padding="0"
              borderRight
              style={{
                width: '12mm',
                justifyContent: "center",
                textAlign: "center",
                fontSize: "2mm",
              }}
            >
              <Text>リハー</Text>
              <Text>サル室</Text>
            </Col>
            <Col padding="0"
              borderRight
              style={{
                width: '12mm',
                justifyContent: "center",
                textAlign: "center",
                fontSize: "2mm",
              }}
            >
              <Text>レセプショ</Text>
              <Text>ン ルーム</Text>
            </Col>
            <Col padding="0"
              style={{
                width: '72mm',
                justifyContent: "center"
              }}
            >
              <Grid>
                <Col padding="0"
                  style={{
                    width: '72mm',
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                >
                  <Text>音楽練習室</Text>
                </Col>
              </Grid>
              <Grid borderTop>
                <Col padding="0"
                  borderRight
                  style={{
                    width: '12mm',
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                >
                  <Text>①</Text>
                </Col>
                <Col padding="0"
                  borderRight
                  style={{
                    width: '12mm',
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                >
                  <Text>②</Text>
                </Col>
                <Col padding="0"
                  borderRight
                  style={{
                    width: '12mm',
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                >
                  <Text>③</Text>
                </Col>
                <Col padding="0"
                  borderRight
                  style={{
                    width: '12mm',
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                >
                  <Text>④</Text>
                </Col>
                <Col padding="0"
                  borderRight
                  style={{
                    width: '12mm',
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                >
                  <Text>⑤</Text>
                </Col>
                <Col padding="0"
                  style={{
                    width: '12mm',
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                >
                  <Text>⑥</Text>
                </Col>
              </Grid>
            </Col>
          </Grid>
  
          <Grid borderTop borderLeft borderRight>
            <Col
              borderRight
              style={{
                width: '35mm',
                justifyContent: "center"
              }}
            >
              <Text>午前 9:00-12:00</Text>
            </Col>
            <Col padding="0"
              borderRight
              style={{
                width: '17mm',
                justifyContent: "center"
              }}
            >
            </Col>
            <Col padding="0"
              borderRight
              style={{
                width: '17mm',
                justifyContent: "center"
              }}
            >
            </Col>
            <Col padding="0"
              borderRight
              style={{
                width: '17mm',
                justifyContent: "center"
              }}
            >
            </Col>
            <Col padding="0"
              borderRight
              style={{
                width: '18mm',
                justifyContent: "center",
                fontSize: "2mm",
                textAlign: "center",
              }}
            >
              <Text>リハーサル</Text>
              <Text>本公演</Text>
            </Col>
            <Col padding="0"
              borderRight
              style={{
                width: '12mm',
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <Text>14,000</Text>
            </Col>
            <Col padding="0"
              borderRight
              style={{
                width: '12mm',
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <Text>46,000</Text>
            </Col>
            <Col padding="0"
              borderRight
              style={{
                width: '12mm',
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <Text>1,300</Text>
            </Col>
            <Col padding="0"
              borderRight
              style={{
                width: '12mm',
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <Text>1,100</Text>
            </Col>
            <Col padding="0"
              borderRight
              style={{
                width: '12mm',
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <Text>1,300</Text>
            </Col>
            <Col padding="0"
              borderRight
              style={{
                width: '12mm',
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <Text>1,300</Text>
            </Col>
            <Col padding="0"
              borderRight
              style={{
                width: '12mm',
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <Text>1,300</Text>
            </Col>
            <Col padding="0"
              style={{
                width: '12mm',
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <Text>1,300</Text>
            </Col>
          </Grid>
  
          <Grid borderTop borderLeft borderRight>
            <Col
              borderRight
              style={{
                width: '35mm',
                justifyContent: "center"
              }}
            >
              <Text>午後13:00-16:30</Text>
            </Col>
            <Col padding="0"
              borderRight
              style={{
                width: '17mm',
                justifyContent: "center"
              }}
            >
            </Col>
            <Col padding="0"
              borderRight
              style={{
                width: '17mm',
                justifyContent: "center"
              }}
            >
            </Col>
            <Col padding="0"
              borderRight
              style={{
                width: '17mm',
                justifyContent: "center"
              }}
            >
            </Col>
            <Col padding="0"
              borderRight
              style={{
                width: '18mm',
                justifyContent: "center",
                fontSize: "2mm",
                textAlign: "center",
              }}
            >
              <Text>リハーサル</Text>
              <Text>本公演</Text>
            </Col>
            <Col padding="0"
              borderRight
              style={{
                width: '12mm',
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <Text>16,000</Text>
            </Col>
            <Col padding="0"
              borderRight
              style={{
                width: '12mm',
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <Text>54,000</Text>
            </Col>
            <Col padding="0"
              borderRight
              style={{
                width: '12mm',
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <Text>1,500</Text>
            </Col>
            <Col padding="0"
              borderRight
              style={{
                width: '12mm',
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <Text>1,300</Text>
            </Col>
            <Col padding="0"
              borderRight
              style={{
                width: '12mm',
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <Text>1,500</Text>
            </Col>
            <Col padding="0"
              borderRight
              style={{
                width: '12mm',
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <Text>1,500</Text>
            </Col>
            <Col padding="0"
              borderRight
              style={{
                width: '12mm',
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <Text>1,500</Text>
            </Col>
            <Col padding="0"
              style={{
                width: '12mm',
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <Text>1,500</Text>
            </Col>
          </Grid>
  
          <Grid borderTop borderLeft borderRight>
            <Col
              borderRight
              style={{
                width: '35mm',
                justifyContent: "center"
              }}
            >
              <Text>夜間17:30-22:00</Text>
            </Col>
            <Col padding="0"
              borderRight
              style={{
                width: '17mm',
                justifyContent: "center"
              }}
            >
            </Col>
            <Col padding="0"
              borderRight
              style={{
                width: '17mm',
                justifyContent: "center"
              }}
            >
            </Col>
            <Col padding="0"
              borderRight
              style={{
                width: '17mm',
                justifyContent: "center"
              }}
            >
            </Col>
            <Col padding="0"
              borderRight
              style={{
                width: '18mm',
                justifyContent: "center",
                fontSize: "2mm",
                textAlign: "center",
              }}
            >
              <Text>リハーサル</Text>
              <Text>本公演</Text>
            </Col>
            <Col padding="0"
              borderRight
              style={{
                width: '12mm',
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <Text>21,000</Text>
            </Col>
            <Col padding="0"
              borderRight
              style={{
                width: '12mm',
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <Text>70,000</Text>
            </Col>
            <Col padding="0"
              borderRight
              style={{
                width: '12mm',
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <Text>2,000</Text>
            </Col>
            <Col padding="0"
              borderRight
              style={{
                width: '12mm',
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <Text>1,800</Text>
            </Col>
            <Col padding="0"
              borderRight
              style={{
                width: '12mm',
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <Text>2,000</Text>
            </Col>
            <Col padding="0"
              borderRight
              style={{
                width: '12mm',
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <Text>2,000</Text>
            </Col>
            <Col padding="0"
              borderRight
              style={{
                width: '12mm',
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <Text>2,000</Text>
            </Col>
            <Col padding="0"
              style={{
                width: '12mm',
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <Text>2,000</Text>
            </Col>
          </Grid>
  
          <Grid borderTop borderLeft borderRight>
            <Col
              borderRight
              style={{
                width: '35mm',
                justifyContent: "center"
              }}
            >
              <Text>区分時間帯以外</Text>
            </Col>
            <Col padding="0"
              borderRight
              style={{
                width: '17mm',
                justifyContent: "center"
              }}
            >
            </Col>
            <Col padding="0"
              borderRight
              style={{
                width: '17mm',
                justifyContent: "center"
              }}
            >
            </Col>
            <Col padding="0"
              borderRight
              style={{
                width: '17mm',
                justifyContent: "center"
              }}
            >
            </Col>
            <Col padding="0"
              borderRight
              style={{
                width: '18mm',
                justifyContent: "center",
                fontSize: "2mm",
                textAlign: "center",
              }}
            >
            </Col>
            <Col padding="0"
              borderRight
              style={{
                width: '12mm',
                justifyContent: "center",
                textAlign: "center",
              }}
            >
            </Col>
            <Col padding="0"
              borderRight
              style={{
                width: '12mm',
                justifyContent: "center",
                textAlign: "center",
              }}
            >
            </Col>
            <Col padding="0"
              borderRight
              style={{
                width: '12mm',
                justifyContent: "center",
                textAlign: "center",
              }}
            >
            </Col>
            <Col padding="0"
              borderRight
              style={{
                width: '12mm',
                justifyContent: "center",
                textAlign: "center",
              }}
            >
            </Col>
            <Col padding="0"
              borderRight
              style={{
                width: '12mm',
                justifyContent: "center",
                textAlign: "center",
              }}
            >
            </Col>
            <Col padding="0"
              borderRight
              style={{
                width: '12mm',
                justifyContent: "center",
                textAlign: "center",
              }}
            >
            </Col>
            <Col padding="0"
              borderRight
              style={{
                width: '12mm',
                justifyContent: "center",
                textAlign: "center",
              }}
            >
            </Col>
            <Col padding="0"
              style={{
                width: '12mm',
                justifyContent: "center",
                textAlign: "center",
              }}
            >
            </Col>
          </Grid>
  
          <Grid borderTop borderLeft borderRight>
            <Col
              borderRight
              style={{
                width: '35mm',
                justifyContent: "center"
              }}
            >
              <Text>開演時間</Text>
            </Col>
            <Col padding="0"
              borderRight
              style={{
                width: '17mm',
                justifyContent: "center"
              }}
            >
            </Col>
            <Col padding="0"
              borderRight
              style={{
                width: '17mm',
                justifyContent: "center"
              }}
            >
            </Col>
            <Col padding="0"
              borderRight
              style={{
                width: '17mm',
                justifyContent: "center"
              }}
            >
            </Col>
            <Col padding="0"
              borderRight
              style={{
                width: '18mm',
                justifyContent: "center",
                fontSize: "2mm",
                textAlign: "center",
              }}
            >
            </Col>
            <Col padding="0"
              borderRight
              style={{
                width: '12mm',
                justifyContent: "center",
                textAlign: "center",
              }}
            >
            </Col>
            <Col padding="0"
              borderRight
              style={{
                width: '12mm',
                justifyContent: "center",
                textAlign: "center",
              }}
            >
            </Col>
            <Col padding="0"
              borderRight
              style={{
                width: '12mm',
                justifyContent: "center",
                textAlign: "center",
              }}
            >
            </Col>
            <Col padding="0"
              borderRight
              style={{
                width: '12mm',
                justifyContent: "center",
                textAlign: "center",
              }}
            >
            </Col>
            <Col padding="0"
              borderRight
              style={{
                width: '12mm',
                justifyContent: "center",
                textAlign: "center",
              }}
            >
            </Col>
            <Col padding="0"
              borderRight
              style={{
                width: '12mm',
                justifyContent: "center",
                textAlign: "center",
              }}
            >
            </Col>
            <Col padding="0"
              borderRight
              style={{
                width: '12mm',
                justifyContent: "center",
                textAlign: "center",
              }}
            >
            </Col>
            <Col padding="0"
              style={{
                width: '12mm',
                justifyContent: "center",
                textAlign: "center",
              }}
            >
            </Col>
          </Grid>
  
  
  
          <Grid borderTop borderLeft borderRight>
            <Col
              style={{
                width: '85mm',
                justifyContent: "center"
              }}
            >
              <Text>利用附帯設備・別紙のとおり</Text>
            </Col>
            <Col padding="0" size="6"
              style={{
                width: '105mm',
                justifyContent: "center"
              }}
            >
            </Col>
          </Grid>
  
          <Grid borderTop borderLeft>
            <Col
              borderRight
              style={{
                width: '35mm',
                justifyContent: "center"
              }}
            >
              <Text>入場料等</Text>
              <Text>の最高額</Text>
            </Col>
            <Col padding="0" size="6"
              style={{
                width: '165mm',
                justifyContent: "center"
              }}
            >
              <Grid>
                <Col borderRight borderBottom style={{ width: '72mm', textAlign:"center" }} size="3">
                  <Text>大ホール</Text>
                </Col>
                <Col borderRight borderBottom style={{ width: '72mm', textAlign:"center" }} size="3">
                  <Text>小ホール</Text>
                </Col>
              </Grid>
              <Grid>
                <Col borderBottom style={{ borderBottomStyle: 'dashed', width: '30mm' }} size="3">
                  <Text style={dataApplication.venue==10 && dataApplication.plan.price==1 ? styles.select : undefined}>　 無料〜2,000円以下</Text>
                </Col>
                <Col borderBottom borderRight style={{ borderBottomStyle: 'dashed', width: '42mm', textAlign:"right" }} size="3">
                  <Text></Text>
                </Col>
                <Col borderBottom style={{ borderBottomStyle: 'dashed', width: '30mm' }} size="3">
                  <Text style={dataApplication.venue==20 && dataApplication.plan.price==1 ? styles.select : undefined}>　 無料〜3,000円以下</Text>
                </Col>
                <Col borderBottom borderRight style={{ borderBottomStyle: 'dashed', width: '42mm', textAlign:"right" }} size="3">
                  <Text></Text>
                </Col>
              </Grid>
              <Grid>
                <Col borderBottom style={{ borderBottomStyle: 'dashed', width: '30mm' }} size="3">
                  <Text style={dataApplication.venue==10 && dataApplication.plan.price==2 ? styles.select : undefined}>　2,001〜5,000円以下</Text>
                </Col>
                <Col borderBottom borderRight style={{ borderBottomStyle: 'dashed', width: '42mm', textAlign:"right" }} size="3">
                  <Text></Text>
                </Col>
                <Col style={{ borderBottomStyle: 'dashed', width: '30mm' }} size="3">
                  <Text style={dataApplication.venue==20 && dataApplication.plan.price==2 ? styles.select : undefined}>　　　　 3,001円以上</Text>
                </Col>
                <Col borderRight style={{ borderBottomStyle: 'dashed', width: '42mm', textAlign:"right" }} size="3">
                  <Text></Text>
                </Col>
              </Grid>
              <Grid>
                <Col style={{ borderBottomStyle: 'dashed', width: '30mm' }} size="3">
                  <Text style={dataApplication.venue==10 && dataApplication.plan.price==3 ? styles.select : undefined}>　　 　　 5,001円以上</Text>
                </Col>
                <Col borderRight style={{ borderBottomStyle: 'dashed', width: '42mm', textAlign:"right" }} size="3">
                  <Text></Text>
                </Col>
                <Col style={{ borderBottomStyle: 'dashed', width: '30mm' }} size="3">
                </Col>
                <Col borderRight style={{ borderBottomStyle: 'dashed', width: '42mm' }} size="3">
                </Col>
              </Grid>
            </Col>
          </Grid>
  
          <Grid borderTop borderLeft borderRight>
            <Col
              style={{
                width: '85mm',
                justifyContent: "center"
              }}
            >
            </Col>
            <Col padding="2mm 10mm" size="6"
              style={{
                width: '115mm',
                height: '20mm',
                justifyContent: "start",
                textAlign: "right"
              }}
            >
              <Text>受付印</Text>
            </Col>
          </Grid>
  
          <Grid borderBottom borderTop borderLeft borderRight>
            <Col
              style={{
                width: '85mm',
                justifyContent: "center"
              }}
            >
              <Text>備考・公演内容等</Text>
            </Col>
            <Col padding="0" size="6"
              style={{
                width: '115mm',
                justifyContent: "center"
              }}
            >
            </Col>
          </Grid>
          <Text style={{ fontSize: "2.5mm" }}>(注意)　※印欄には, 記入しないでください。</Text>
  
        </View>
      </Page>
    </Document>
      );
  } else {
    return (
      <Document>
        <Page size="A4" style={styles.page}>
        </Page>
      </Document>
    );
  }
}
