import { React } from "react";
import { useMyData } from './MyProvider';


export default function ApplicationAddCalDay(props) {

  const { dataApplication, setDataApplication } = useMyData();

  let req;
  if(props.dataApplication.mode == 'step1') {
    req = props.dataApplication.req1 || [];
  }
  if(props.dataApplication.mode == 'step2') {
    req = props.dataApplication.req2 || [];
  }
  if(props.dataApplication.mode == 'step3') {
    req = props.dataApplication.req3 || [];
  }

  let reserveda = true;
  let reservedb = true;
  let reservedc = true;

  let reserveda2 = true;
  let reservedb2 = true;
  let reservedc2 = true;

  props.dataApplication.reserved.forEach(function(data){
    const reservedDate = new Date(data.sdate);
    if(props.day.date){
      if(
        reservedDate.getFullYear() == props.day.date.getFullYear() &&
        reservedDate.getMonth() == props.day.date.getMonth() &&
        reservedDate.getDate() == props.day.date.getDate() 
      ) {
        if(data.term==1) {
          reserveda = false;
        }
        if(data.term==2) {
          reservedb = false;
        }
        if(data.term==3) {
          reservedc = false;
        }
      }
    }
  });

  
  const tsdate = new Date(props.dataApplication.tsdate);
  const tedate = new Date(props.dataApplication.tedate);
  if(props.day.date < tsdate) {
    reserveda = false;
    reservedb = false;
    reservedc = false;
  }
  if(props.day.date > tedate) {
    reserveda = false;
    reservedb = false;
    reservedc = false;
  }

  const updateCheck = (e) => {
    let targetValue = e.target.value;
    let term = targetValue.substr(-1);
    let date = targetValue.substr(0,targetValue.length -1 );

    if (req.includes(e.target.value)) {
      req = req.filter(item => item !== e.target.value);
    } else {
      req = [...req, e.target.value];
      req = req.filter(item => ( item == date + 'a' || item == date + 'b' || item == date + 'c' ) );
    }
    // 午前と夜間を選択したら、午後も自動的に選択
    if (req.includes(date + 'a') && req.includes(date + 'c')) {
      if (req.includes(date + 'b')) {
      } else {
        req = [...req, date + 'b'];
      }
    }


    if(props.dataApplication.mode == 'step1') {
      setDataApplication({
        ...dataApplication,
        "req1": req,
      })
    }
    if(props.dataApplication.mode == 'step2') {
      setDataApplication({
        ...dataApplication,
        "req2": req,
      })
    }
    if(props.dataApplication.mode == 'step3') {
      setDataApplication({
        ...dataApplication,
        "req3": req,
      })
    }

  }
  
  

  const id = props.day.date 
   ? props.day.date.getFullYear() + '-' + ( "0" + (props.day.date.getMonth() + 1) ).substr(-2) + '-' + ("0" + props.day.date.getDate()).substr(-2)
   : undefined;


   let reqA = [];
   let reqB = [];
   if(props.dataApplication.mode == 'step1') {
     reqA = props.dataApplication.req2 || [];
     reqB = props.dataApplication.req3 || [];
   }
   if(props.dataApplication.mode == 'step2') {
     reqA = props.dataApplication.req1 || [];
     reqB = props.dataApplication.req3 || [];
   }
   if(props.dataApplication.mode == 'step3') {
     reqA = props.dataApplication.req1 || [];
     reqB = props.dataApplication.req2 || [];
   }
   if(reqA.includes(id + 'a')) {reserveda = false; reserveda2 = false;}
   if(reqA.includes(id + 'b')) {reservedb = false; reservedb2 = false;}
   if(reqA.includes(id + 'c')) {reservedc = false; reservedc2 = false;}
   if(reqB.includes(id + 'a')) {reserveda = false; reserveda2 = false;}
   if(reqB.includes(id + 'b')) {reservedb = false; reservedb2 = false;}
   if(reqB.includes(id + 'c')) {reservedc = false; reservedc2 = false;}
   
   

  return (
    <>
      {props.day.date ? 
      <>
        {reserveda ? <td><input type="checkbox" checked={req.includes(id + 'a') ? "checked" : ""} value={id + 'a'} onChange={updateCheck} id={'cal' + id + 'a'} /><label htmlFor={'cal' + id + 'a'}>&#x25CB;</label></td> 
                   : reserveda2 ? <td>&#x00D7;</td> : <td className="closed">&#x25CB;</td>}
        {reservedb ? <td><input type="checkbox" checked={req.includes(id + 'b') ? "checked" : ""} value={id + 'b'} onChange={updateCheck} id={'cal' + id + 'b'} /><label htmlFor={'cal' + id + 'b'}>&#x25CB;</label></td> 
                   : reservedb2 ? <td>&#x00D7;</td> : <td className="closed">&#x25CB;</td>}
        {reservedc ? <td><input type="checkbox" checked={req.includes(id + 'c') ? "checked" : ""} value={id + 'c'} onChange={updateCheck} id={'cal' + id + 'c'} /><label htmlFor={'cal' + id + 'c'}>&#x25CB;</label></td> 
                   : reservedc2 ? <td>&#x00D7;</td> : <td className="closed">&#x25CB;</td>}
      </>
      :
      <>
        <td className="spnone"></td>
        <td className="spnone"></td>
        <td className="spnone"></td>
      </>
      }
    </>
 );

}
