import { React, useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { useMyData } from './MyProvider';
import { useInput, useCheckbox } from "./hooks";
import ApplyDetailPdf2 from "./ApplyDetailPdf2";
import { usePDF, Document, Page, Text, View,  } from '@react-pdf/renderer';

export default function ApplicationAddEnd() {
  let {id} = useParams();

  const { dataApplication, setDataApplication,myInfo,dataMy,addApplication } = useMyData();

  const [sendCheck, setSendCheck] = useState("WAIT"); // WITT:送信中 OK:完了 NG:失敗
  const [numberData, setNumberData] = useState(""); // 申し込み番号

  const [instance2, updateInstance2] = usePDF({ document: <ApplyDetailPdf2 data={dataApplication} /> });

  useEffect(() => {
    if(dataApplication.mode=='step7') {
      addApplication(id,setSendCheck,setNumberData);
    }
    if(dataApplication.mode=='step8') {
      setSendCheck("OK");
    }
  },[]);

  useEffect(() => {
    if(sendCheck=="OK") {
      const num = numberData != "" ? numberData : dataApplication.appnum ;
      setDataApplication( {
        ...dataApplication,
        //step1:{},
        //step2:{},
        //step3:{},
        //req1:[],
        //req2:[],
        //req3:[],
        //apply:{},
        //plan:{},
        mode: "step8",
        appnum: num,
      });
      updateInstance2();
    }
  },[sendCheck]);

  const prevStep = () => {
    window.scrollTo({
      top: 0,
    });

    setDataApplication({
      ...dataApplication,
      "mode": "step6",
    })
  };
  return (
    <>
      <h1 className="pagetopLabel">抽選申込完了</h1>

      <ul className="Progressbar">
        <li className="complete"><span>第一希望選択</span></li>
        <li className="complete"><span>第二希望選択</span></li>
        <li className="complete"><span>第三希望選択</span></li>
        <li className="complete"><span>利用許可<br />申請情報の入力</span></li>
        <li className="complete"><span>公演計画<br />申請情報の入力</span></li>
        <li className="complete"><span>確認</span></li>
        <li className="active"><span>抽選申込完了</span></li>
      </ul>


      { sendCheck=="WAIT" && <h6>抽選申込中</h6> }
      { sendCheck=="OK" && <h6>抽選申込完了</h6> }
      { sendCheck=="NG" && <h6>抽選申込失敗</h6> }

      { sendCheck=="WAIT" && <h3>申請中………</h3> }
      { sendCheck=="OK" && <h3>申請完了</h3> }
      { sendCheck=="NG" && <h3>申請失敗</h3> }

      { sendCheck=="WAIT" && 
      <div className="txt">
        <p>
        大小ホールの抽選申込の送信中です。<br /><br />
        しばらくおまちください。<br /><br />
        <br /><br />
        申込内容を確認のうえ、運営チーム担当より確認のご連絡させていただく場合がございます。<br /><br />
        抽選申込の内容は【マイページ】→【公演情報一覧】→【大小ホール抽選履歴】より確認・ダウンロードいただけます。<br /><br />
        内容変更は、申込期限内に貸館受付メールアドレス（apply_mmh@yaf.or.jp）もしくは運営チーム宛（TEL:045682-2020）にご連絡ください。<br />
        抽選申込の結果は、抽選会終了後、【大小ホール抽選履歴】より、当落をご確認いただけます。
        </p>
      </div>
      }
      { sendCheck=="OK" && 
      <div className="txt">
        <p>
        大小ホールの抽選申込の送信を完了いたしました。<br /><br />
        あなたの申請受付番号は、【 {dataApplication.appnum} 】番です<br /><br />
        申込内容を確認のうえ、運営チーム担当より確認のご連絡させていただく場合がございます。<br /><br />
        抽選申込の内容は【マイページ】→【公演情報一覧】→【大小ホール抽選履歴】より確認・ダウンロードいただけます。<br /><br />
        内容変更は、申込期限内に貸館受付メールアドレス（apply_mmh@yaf.or.jp）もしくは運営チーム宛（TEL:045682-2020）にご連絡ください。<br />
        抽選申込の結果は、抽選会終了後、【大小ホール抽選履歴】より、当落をご確認いただけます。
        </p>
      </div>
      }
      { sendCheck=="NG" && 
      <div className="txt">
        <p>
        大小ホールの抽選申込の送信に失敗しました。<br /><br />
        <br /><br />
        申込内容を確認のうえ、運営チーム担当より確認のご連絡させていただく場合がございます。<br /><br />
        抽選申込の内容は【マイページ】→【公演情報一覧】→【大小ホール抽選履歴】より確認・ダウンロードいただけます。<br /><br />
        内容変更は、申込期限内に貸館受付メールアドレス（apply_mmh@yaf.or.jp）もしくは運営チーム宛（TEL:045682-2020）にご連絡ください。<br />
        抽選申込の結果は、抽選会終了後、【大小ホール抽選履歴】より、当落をご確認いただけます。
        </p>
      </div>
      }

      <div className="Form__btnbox">
        { sendCheck=="NG" &&
        <div className="Form__btn-return">
          <input type="button" value="戻る" onClick={prevStep} />
        </div>
        }
        { sendCheck=="OK" &&
        <>
          <div className="Form__btn-return">
            <Link to="/">マイページTOPへ</Link>
          </div>
          <div className="Form__btn-return">
            <a href={instance2.url} download={"抽選申込情報" + dataApplication.appnum + ".pdf"}>公演計画書ダウンロード</a>
          </div>
        </>
        }
      </div>
    </>
  );

}
